import React from 'react';
import { ClipLoader } from 'react-spinners';
import { FiPlusCircle } from 'react-icons/fi';

import { Button } from './styles';

interface ILoadingMore {
   loading: boolean;
   callback(): void;
}

const LoadingMore = (props: ILoadingMore) => {
   return (
      <Button type='button' onClick={props.callback}>
         <div className='icon'>
            {!props.loading && <FiPlusCircle size={22} />}
            {props.loading && <ClipLoader size={22} color='#fff' loading/>}
         </div>
         <div className='text'>Carregar mais</div>
      </Button>
   );
}

export default LoadingMore;