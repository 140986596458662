import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto 20px auto;
  font-weight: 300;
  border-radius: 8px;
  border: 0;
  background: #0F4C81;
  color: #fff;
  .text {
    padding: 6px;
  }
  .icon {
    display: flex;
    padding: 6px;
    background: #1569b2;
    border-radius: 8px 0 0 8px;
    margin: 0 auto;
  }

  @media(max-width: 450px) {
    .text {
      border-radius: 8px;
      font-size: 12px;;
    }
    // .icon {
    //    display: none;        
    // }
  }
`;

