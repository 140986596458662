import React, { useRef, useState, useEffect, useCallback } from 'react';
import { getDay } from 'date-fns';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';
import { Container, Error } from './styles';

import 'react-datepicker/dist/react-datepicker.css';


interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
}

const DatePicker: React.FC<Props> = ({ 
  name, 
  containerStyle = {},
  icon: Icon,
  ...rest 
}) => {
  const datepickerRef = useRef(null);

  registerLocale("ptBR", ptBR);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState(defaultValue || null);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!datepickerRef.current);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  const isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <Container
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      data-testid="date-container"
    >
      {Icon && <Icon size={20} />}

      <ReactDatePicker
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        dateFormat='dd/MM/yyyy'
        filterDate={isWeekday}
        useWeekdaysShort={true}
        ref={datepickerRef}
        selected={date}
        onChange={setDate}
        locale="ptBR"
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default DatePicker;