import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { FiPlusCircle, FiMinusCircle, FiMoreHorizontal } from 'react-icons/fi';
import { useParams, useLocation } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import { Table, TableRow } from '@material-ui/core';

import Header from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import LoadingMore from '../../components/LoadingMore';

import { 
  Container, Titulo, Cadastro, Tabela, TabelaPai, StyledTableHead, StyledTableBody, 
  StrongHeader, StyledTableContainer, StyledTableCell
} from './styles';

import api from '../../services/api';

interface IParametros {
  cliente: string;
}

interface IEmpresa {
  codigoempresa: string;
	nomefantasia: string;
}

interface IPedidoVenda {
  npedidov: string;
  codigoCliente: string;
  dataEmissao: string;
  dataEntrega: string;
  pedidoCliente: string;
  situacao: string;
  PEDIDOVENDAPRODUTO: IPedidoVendaProduto[];
}

interface IPedidoVendaProduto {
  npedidov: string;
  sequencia: string;
  codigoproduto: string;
  descrinota: string;
  quantidade: number;
  precototal: number;
}

interface ILocation {
  searchClient: string;
}

const Pedidos: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [togglePedidoVenda, setTogglePedidoVenda] = useState(false);
  const [togglePlusPedidoVenda, setTogglePlusPedidoVenda] = useState(false);
  const [indexDivPedidoVenda, setIndexDivPedidoVenad] = useState(-1);
  
  const [empresa, setEmpresa] = useState<IEmpresa>();
  const [pedidosVendas, setPedidosVendas] = useState<IPedidoVenda[]>([]);

  const { cliente } = useParams<IParametros>();

  const location = useLocation<ILocation>();

  useEffect(() => { 
    async function getPedidos() {
      try {
        if (currentPage === 1) {
          setLoading(true);

          const resEmpresa = await api.get<IEmpresa>(`/empresas/${cliente}`);
          setEmpresa(resEmpresa.data);
        }

        if (currentPage > 1) {
          setLoadingRows(true); 
        }

        const resPedidos = await api.get<IPedidoVenda[]>(`/pedidovenda/${cliente}?page=${currentPage}`);
        const formattedPedidos = resPedidos.data.map(pedidoVenda => {
          return {
            ...pedidoVenda,
            dataEmissao: format(new Date(
              new Date(pedidoVenda.dataEmissao).getUTCFullYear(), 
              new Date(pedidoVenda.dataEmissao).getUTCMonth(), 
              new Date(pedidoVenda.dataEmissao).getUTCDate()
            ), 'dd/MM/yyyy'),
            dataEntrega: format(new Date(
              new Date(pedidoVenda.dataEntrega).getUTCFullYear(), 
              new Date(pedidoVenda.dataEntrega).getUTCMonth(), 
              new Date(pedidoVenda.dataEntrega).getUTCDate()
            ), 'dd/MM/yyyy'),
          };
        });
        setPedidosVendas((prevPedidos) => [...prevPedidos, ...formattedPedidos]);
        
      //} catch (error) {
      //  alert('Cliente inválido!');
      } finally {
        setLoading(false);
      }
    }   
    getPedidos();

  }, [cliente, currentPage]);

  function handleTogglePedidoVenda(index: number) {
    setIndexDivPedidoVenad(index);
    setTogglePedidoVenda(true);

    if (index === indexDivPedidoVenda && togglePedidoVenda) {
      setTogglePedidoVenda(!togglePedidoVenda);
    }
  };

  function handleTogglePlusPedidoVenda() {
    setTogglePlusPedidoVenda(!togglePlusPedidoVenda);

    if (!togglePlusPedidoVenda) {
      setTogglePedidoVenda(false);
    }
  };

  const handleLoadingMore = () => {
    if (currentPage > 6) {
      setCurrentPage(6);
      return;
    }
    setCurrentPage((currentValue) => currentValue + 1);
  }

  if (loading) {
    return (
      <LoadingPage message='Buscando pedidos do cliente' />
    );
  }  

  return (
    <Container>
      <Header headerType={'empresa'} empresa={empresa} search={location.state.searchClient} />

      <Cadastro>
        <Titulo>
          <h1>Pedidos de Vendas</h1>
          <button type="button" onClick={() => { handleTogglePlusPedidoVenda() }}>
            {pedidosVendas.length > 0 && !togglePlusPedidoVenda && <FiPlusCircle />}
            {pedidosVendas.length > 0 && togglePlusPedidoVenda && <FiMinusCircle />}
          </button>
        </Titulo>

        {pedidosVendas.length === 0 && <p>Sem registros...</p>}

        {pedidosVendas && pedidosVendas.map((pedidoVenda, index) => (
          <Tabela key={index}>

            <TabelaPai>
              <div className="class-pedido-venda-headers">
                <span><strong>Nº Pedido: </strong>{pedidoVenda.npedidov}</span>
                <span><strong>Dt. Emissão: </strong>{pedidoVenda.dataEmissao}</span>
                <span><strong>Situação: </strong>{pedidoVenda.situacao}</span>
                <span><strong>Dt. Entrega: </strong>{pedidoVenda.dataEntrega}</span>
                {pedidoVenda.pedidoCliente && <span><strong>Nº Pedido Cliente: </strong>{pedidoVenda.pedidoCliente}</span>}
              </div>

              <button type="button" onClick={() => { handleTogglePedidoVenda(index) }}>
                {!togglePlusPedidoVenda && <FiMoreHorizontal />}
              </button>
            </TabelaPai>

            <Spring
              native
              force
              config={{ tension: 5000, friction: 100, precision: 1 }}
              from={{ height: (index === indexDivPedidoVenda && togglePedidoVenda) || togglePlusPedidoVenda ? 0 : 'auto' }}
              to={{ height: (index === indexDivPedidoVenda && togglePedidoVenda) || togglePlusPedidoVenda ? 'auto' : 0 }}
            >              
              {props => (
                <animated.div style={props}>
                  <StyledTableContainer>
                    <Table size="small">
                      <StyledTableHead>
                        <TableRow>
                          <StyledTableCell align="left"><StrongHeader>Código</StrongHeader></StyledTableCell>
                          <StyledTableCell align="left"><StrongHeader>Produto</StrongHeader></StyledTableCell>
                          <StyledTableCell align="right"><StrongHeader>Quantidade</StrongHeader></StyledTableCell>
                          <StyledTableCell align="right"><StrongHeader>Valor</StrongHeader></StyledTableCell>
                        </TableRow>
                      </StyledTableHead>
                      <StyledTableBody>
                        {pedidoVenda.PEDIDOVENDAPRODUTO?.map((pedidoVendaProduto, index) => (
                          <TableRow key={index}>
                            <StyledTableCell align="left" width="12%">{pedidoVendaProduto.codigoproduto}</StyledTableCell>
                            <StyledTableCell align="left">{pedidoVendaProduto.descrinota}</StyledTableCell>
                            <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(pedidoVendaProduto.quantidade)}</StyledTableCell>
                            <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(pedidoVendaProduto.precototal)}</StyledTableCell>
                          </TableRow>
                        ))}
                      </StyledTableBody>
                    </Table>
                  </StyledTableContainer>                
                </animated.div>
              )}
            </Spring>

          </Tabela>
        ))}
      </Cadastro>

      {pedidosVendas.length > 0 && Number.isInteger(pedidosVendas.length / 50) && currentPage <= 6 &&
        <LoadingMore 
          loading={loadingRows}
          callback={handleLoadingMore}
        />        
      }
    </Container>
  );
};

export default Pedidos;
