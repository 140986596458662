import styled from 'styled-components';
import { shade } from 'polished';
import { TableBody, TableHead, TableContainer, TableCell } from '@material-ui/core';

interface IProps {
  isLoading?: boolean;
}

export const StyledTableCell = styled(TableCell)`
  && {
    font-size: 13px;

    @media(max-width: 980px) {
      font-size: 11px;
    }
    @media(max-width: 620px) {
      font-size: 10px;
    }
    @media(max-width: 450px) {
      font-size: 9px;
    }
  }
`;

export const StyledTableHead = styled(TableHead)`
  background: #cacad8;
`;

export const StyledTableBody = styled(TableBody)`
  background: #f0f0f5; 
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 15px;
`;

export const StrongHeader = styled.div`
  font-weight: bold;
`;

export const Container = styled.div<IProps>`
  font-size: 62.5%;
  margin-top: ${props => props.isLoading ? '-30px' : '0px'};

  input {
    display: flex;
    margin-top: 5px;
    background: #E3E3E3;
    border-radius: 10px;
    padding: 16px;
    width: 100%;    
    border: 2px solid #0F4C81;
    color: #373435;

    &::placeholder {
      color: #666360;
    }
  }
`;

export const Cadastro = styled.div`
  width: 95%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 14px 0;

  p {
    padding: 10px 0px;
    font-size: 16px;

    @media(max-width: 980px) {
      font-size: 14px;
    }    
    @media(max-width: 620px) {
      font-size: 12px;
    }
    @media(max-width: 450px) {
      font-size: 10px;
    }
  }
`;

export const Titulo = styled.div`
  display: flex;
  width: 100%;
  vertical-align: middle;

  h1 {
    font-size: 24px;

    @media(max-width: 450px) {
      font-size: 20px;
    }
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #6e686a;
      width: 24px;
      height: 24px;

      &:hover {
        color: ${shade(0.2, '#0F4C81')};
      }
    }    
  }
`;

export const Tabela = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 10px 0px;
  border-radius: 8px;
  background: #E4E4EB;
  overflow: hidden;
`;

export const TabelaPai = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 5px;
  background: #E4E4EB;

  div {
    display: flex;
  }

  @media(max-width: 980px) {
    font-size: 14px;
  }    
  @media(max-width: 620px) {
    font-size: 12px;
  }
  @media(max-width: 450px) {
    font-size: 10px;
  }

  @media(max-width: 370px) {
    div.class-pedido-venda-headers {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  span {
    color: #373435;
    margin-right: 15px;
    // width: 200px;

    strong {
      font-weight: 500;
      color: black;
    }
  }
  
  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #373435;
      width: 18px;
      height: 18px;

      &:hover {
        color: ${shade(0.2, '#0F4C81')};
      }
    }
  }
`;