import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  font-size: 62.5%;

  input {
    display: flex;
    margin-top: 5px;
    background: #E3E3E3;
    border-radius: 10px;
    padding: 16px;
    width: 100%;    
    border: 2px solid #0F4C81;
    color: #373435;

    &::placeholder {
      color: #666360;
    }
  }
`;

export const Cadastro = styled.div`
  width: 95%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 14px 0;

  p {
    font-size: 16px;

    @media(max-width: 980px) {
      font-size: 14px;
    }    
    @media(max-width: 620px) {
      font-size: 12px;
    }
    @media(max-width: 450px) {
      font-size: 10px;
    }
  }
`;

export const Titulo = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;
  vertical-align: middle;

  h1 {
    font-size: 24px;

    @media(max-width: 450px) {
      font-size: 20px;
    }
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #6e686a;
      width: 24px;
      height: 24px;

      &:hover {
        color: ${shade(0.2, '#0F4C81')};
      }
    }    
  }
`;

export const TituloPolitica = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;
  vertical-align: middle;

  h1 {
    font-size: 24px;

    @media(max-width: 450px) {
      font-size: 20px;
    }
  }  

  button {
    align-self: flex-end;
    margin-left: auto;
    font-weight: 300;
    border-radius: 8px;
    border: 0;
    background: #0F4C81;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding: 5px 5px;
    }
    .icon {
      display: flex;
      padding: 5px 5px;
      background: #1569b2;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }

    @media(max-width: 450px) {
      .text {
        border-radius: 8px;
        font-size: 12px;;
      }
      // .icon {
      //    display: none;        
      // }
    }
  }
`;

export const TabelaFilha = styled.div`
  //margin-top: 40px;
`;

export const ComercialContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 15px;

  @media(max-width: 430px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 10px;
  }
`;

export const AgendaContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;

  @media(max-width: 430px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 10px;
  }
`;