import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useParams, useLocation } from 'react-router-dom';
import { Table, TableRow } from '@material-ui/core';
import { FiDownload } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import Header from '../../../components/Header';
import LoadingPage from '../../../components/LoadingPage';
import LoadingMore from '../../../components/LoadingMore';

import { 
  Container, Titulo, Cadastro, StyledTableHead, StyledTableBody, 
  StrongHeader, StyledTableContainer, StyledTableCell
} from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface IParametros {
  empresa: string;
}

interface IEmpresa {
  codigoempresa: string;
	nomefantasia: string;
}

interface IDocumento {
  iddocumento: number;
  idempresa: string;
  numdoc: string;
  datavencimento: string;
  valorfinal: number;
  totalnota: number;
}

interface ILocation {
  searchClient: string;
}

const DocumentosFaturasAbertas: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [empresaState, setEmpresaState] = useState<IEmpresa>();
  const [documentos, setDocumentos] = useState<IDocumento[]>([]);

  const { addToast } = useToast();
  const { empresa } = useParams<IParametros>();
  
  const location = useLocation<ILocation>();

  useEffect(() => { 
    async function getDocumentosAbertos() {
      try {
        if (currentPage === 1) {
          setLoading(true);

          const resEmpresa = await api.get<IEmpresa>(`/empresas/${empresa}`);
          setEmpresaState(resEmpresa.data);
        }

        if (currentPage > 1) {
          setLoadingRows(true); 
        }

        const resDocumentos = await api.get<IDocumento[]>(`/documentos/${empresa}/faturas/abertas?page=${currentPage}`);
        const formattedDocumentos = resDocumentos.data.map(documento => {
          return {
            ...documento,
            datavencimento: format(new Date(
              new Date(documento.datavencimento).getUTCFullYear(), 
              new Date(documento.datavencimento).getUTCMonth(), 
              new Date(documento.datavencimento).getUTCDate()
            ), 'dd/MM/yyyy'),
          };
        });
        setDocumentos((prevDocumentos) => [...prevDocumentos, ...formattedDocumentos]);
        
      //} catch (error) {
      //  alert('Cliente inválido!');
      } finally {
        setLoading(false);
        setLoadingRows(false);
      }
    }
    getDocumentosAbertos();
  }, [empresa, currentPage]);

  async function createPDF() {
    await api.post('/documentos/create-pdf', { 
      // BODY PARAMS no POST
      empresa 
    });
  };

  async function fetchPDF() {
    try {
      const responsePDF = await api.get('/documentos/fetch-pdf', { 
        // GET com BODY PARAMS
        headers: {
          empresa
        },
        responseType: 'blob',
      });

      var nomeFantasia = empresaState?.nomefantasia.replace(" ", "_");

      const pdfBlob = new Blob([responsePDF.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `${empresa}_${nomeFantasia}-FATURAS_ABERTAS.pdf`);

      addToast({
        type: 'success',
        title: 'Download concluído' ,
        description: 'Download do arquivo PDF realizado com sucesso.',
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Erro no download',
        description: 'Ocorreu um erro ao realizar o download do arquivo PDF, tente novamente.',
      });
    }
  };

  async function deletePDF() {
    await api.delete('/comercial/delete-pdf', { 
      // DELETE com BODY PARAMS
      data: {
        empresa
      }
    });
  };

  async function handleDownloadPDF() {
    try {
      setLoadingPDF(true);
      await createPDF();

      setTimeout(() => {
        fetchPDF();
      }, 3500);

      setTimeout(() => {
        deletePDF();
        setLoadingPDF(false);
      }, 5500);

    } catch (err) {
      setLoadingPDF(false);
    }
  };

  const handleLoadingMore = () => {
    if (currentPage > 6) {
      setCurrentPage(6);
      return;
    }
    setCurrentPage((currentValue) => currentValue + 1);
  }

  if (loading) {
    return (
      <LoadingPage message='Buscando faturas abertas da empresa' />
    );
  }

  return (
    <Container>
      <Header headerType={'financeiro'} empresa={empresaState} search={location.state.searchClient} />

      <Cadastro>
        <Titulo>
          <h1>Faturas Abertas</h1>

          {documentos.length !== 0 && 
            <button type="button" onClick={() => { handleDownloadPDF() }}>
              
              <div className="text">Download</div>
              <div className="icon">
                  {!loadingPDF && <FiDownload size={22} />}
                  {loadingPDF && <ClipLoader size={22} color='#fff' loading/>}
              </div>
            </button>
          }
        </Titulo>

        {documentos.length === 0 && <p>Sem registros...</p>}

        {documentos.length !== 0 && 
          <StyledTableContainer>
            <Table size="small">
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell align="left"><StrongHeader>Documento</StrongHeader></StyledTableCell>
                  <StyledTableCell align="left"><StrongHeader>Nota Fiscal</StrongHeader></StyledTableCell>
                  <StyledTableCell align="left"><StrongHeader>Data Vencimento</StrongHeader></StyledTableCell>
                  <StyledTableCell align="right"><StrongHeader>Valor Final</StrongHeader></StyledTableCell>
                  <StyledTableCell align="right"><StrongHeader>Valor Total</StrongHeader></StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {documentos.map((documento, index) => (
                  <TableRow key={index}>
                    <StyledTableCell align="left" width="12%">{documento.iddocumento}</StyledTableCell>
                    <StyledTableCell align="left" width="12%">{documento.numdoc}</StyledTableCell>
                    <StyledTableCell align="left">{documento.datavencimento}</StyledTableCell>
                    <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(documento.valorfinal)}</StyledTableCell>
                    <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(documento.totalnota)}</StyledTableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </StyledTableContainer>
        }
      </Cadastro>

      {documentos.length > 0 && Number.isInteger(documentos.length / 50) && currentPage <= 6 &&
        <LoadingMore 
          loading={loadingRows}
          callback={handleLoadingMore}
        />        
      }

      
    </Container>
  );
};

export default DocumentosFaturasAbertas;
