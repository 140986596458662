import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { FiDownload } from 'react-icons/fi';
import { useParams, useLocation } from 'react-router-dom';

import { ClipLoader } from 'react-spinners';
import { saveAs } from 'file-saver';

import { useToast } from '../../hooks/toast';

import Header from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import ComercialComponent from '../../components/Comercial';
import ModalEditComercial from '../../components/ModalEditComercial';
import AgendaComponent from '../../components/Comercial_Agenda';
import ModalAddAgenda from '../../components/ModalAddAgenda';
import ModalEditAgenda from '../../components/ModalEditAgenda';

import { 
  Container, Titulo, Cadastro, ComercialContainer, AgendaContainer, TituloPolitica
} from './styles';

// import api from '~/services/api';
import api from '../../services/api';

interface IParametros {
  cliente: string;
}

interface IEmpresa {
  codigoempresa: string;
	nomefantasia: string;
}

interface IComercial {
  codigocliente: string;
	codigoproduto: string;
	codigoemb: string;
	codigocondicao: string;
	descrinota: string;
	moeda: string;
	precovendamoeda: number;
  precovenda: number;
  capacidade: number;
  imprimefax: string;
  EMBALAGEM: {
    descricao: string;
  }
  PRODUTO: {
    custoRepMoeda: number;
    dataCMP: string;
    codigoFamilia: string;
  }
}

interface IMediaTrimestre {
	CODIGOPRODUTO: string;
  TRIMESTRE: number;
}

interface IMediaSemestre {
	CODIGOPRODUTO: string;
  SEMESTRE: number;
}

interface IAgenda {
  id: number;
  codigoCliente: string;
  dataPrevisao: string;
  dataVisita: string;
  assunto: string;
  conclusao: string;
  previsaoDate?: Date;
  visitaDate?: Date;
}

interface IMoeda {
  moeda: string;
  sigla: string;
}

interface ILocation {
  searchClient: string;
}

const Comercial: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [empresa, setEmpresa] = useState<IEmpresa>();
  const [comercial, setComercial] = useState<IComercial[]>([]);
  const [agenda, setAgenda] = useState<IAgenda>();
  const [mediaTrimestre, setMediaTrimestre] = useState<IMediaTrimestre[]>([]);
  const [mediaSemestre, setMediaSemestre] = useState<IMediaSemestre[]>([]);
  const [moedas, setMoedas] = useState<IMoeda[]>([]);
  
  const [editingComercial, setEditingComercial] = useState<IComercial>({} as IComercial);
  const [editModalComercialOpen, setEditModalComercialOpen] = useState(false);

  const [addingAgenda, setAddingAgenda] = useState<IAgenda>({} as IAgenda);
  const [addModalAgendaOpen, setAddModalAgendaOpen] = useState(false);

  const [editingAgenda, setEditingAgenda] = useState<IAgenda>({} as IAgenda);
  const [editModalAgendaOpen, setEditModalAgendaOpen] = useState(false);

  const { addToast } = useToast();
  const { cliente } = useParams<IParametros>();

  const location = useLocation<ILocation>();

  useEffect(() => { 
    async function getCliente() {
      try {
        setLoading(true);

        const resEmpresa = await api.get<IEmpresa>(`/empresas/${cliente}`);
        setEmpresa(resEmpresa.data);

        const resComercial = await api.get<IComercial[]>(`/comercial/${cliente}`);
        const formattedComercial = resComercial.data.map(comercial => {
          return {
            ...comercial,
            ...(comercial.PRODUTO?.dataCMP && {
              PRODUTO: {
                custoRepMoeda: comercial.PRODUTO?.custoRepMoeda,
                dataCMP: format(new Date(
                  new Date(comercial.PRODUTO?.dataCMP).getUTCFullYear(), 
                  new Date(comercial.PRODUTO?.dataCMP).getUTCMonth(), 
                  new Date(comercial.PRODUTO?.dataCMP).getUTCDate(),
                  new Date(comercial.PRODUTO?.dataCMP).getUTCHours(),
                  new Date(comercial.PRODUTO?.dataCMP).getUTCMinutes(),
                ), 'dd/MM/yyyy HH:mm'),
                codigoFamilia: comercial.PRODUTO?.codigoFamilia,
              }
            }),
          };
        });
        setComercial(formattedComercial);

        const resMoeda = await api.get<IMoeda[]>(`/moeda`);
        setMoedas(resMoeda.data);

        const resAgenda = await api.get<IAgenda>(`/agenda/${cliente}`);
        setAgenda({ 
          ...resAgenda.data, 
          ...(resAgenda.data.dataPrevisao ? {
              dataPrevisao: format(new Date(
                new Date(resAgenda.data.dataPrevisao).getUTCFullYear(), 
                new Date(resAgenda.data.dataPrevisao).getUTCMonth(), 
                new Date(resAgenda.data.dataPrevisao).getUTCDate()
              ), 'dd/MM/yyyy'),
            } : {}
          ),
          ...(resAgenda.data.dataVisita ? {
              dataVisita: format(new Date(
                new Date(resAgenda.data.dataVisita).getUTCFullYear(), 
                new Date(resAgenda.data.dataVisita).getUTCMonth(), 
                new Date(resAgenda.data.dataVisita).getUTCDate()
              ), 'dd/MM/yyyy'),
            } : {}
          ),
        });

        const resMediaTrimestre = await api.get<IMediaTrimestre[]>(`/faturas/trimestre/${cliente}`);
        setMediaTrimestre(resMediaTrimestre.data);

        const resMediaSemestre = await api.get<IMediaSemestre[]>(`/faturas/semestre/${cliente}`);
        setMediaSemestre(resMediaSemestre.data);

      //} catch (error) {
      //  alert('Cliente inválido!');
      } finally {
        setLoading(false);
      }
    }
    
    getCliente();
  }, [cliente]);

  async function createPDF() {
    await api.post('/comercial/create-pdf', { 
      // Passando BODY PARAMS no POST
      codigocliente: cliente 
    });
  };

  async function fetchPDF() {
    try {
      const responsePDF = await api.get('/comercial/fetch-pdf', { 
        // GET com BODY PARAMS
        headers: {
          codigocliente: cliente
        },
        responseType: 'blob',
      });

      var nomeFantasia = empresa?.nomefantasia.replace(" ", "_");

      const pdfBlob = new Blob([responsePDF.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `${cliente}_${nomeFantasia}.pdf`);

      addToast({
        type: 'success',
        title: 'Download concluído' ,
        description: 'Download do arquivo PDF realizado com sucesso.',
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Erro no download',
        description: 'Ocorreu um erro ao realizar o download do arquivo PDF, tente novamente.',
      });
    }
  };

  async function deletePDF() {
    await api.delete('/comercial/delete-pdf', { 
      // DELETE com BODY PARAMS
      data: {
        codigocliente: cliente
      }
    });
  };

  async function handleDownloadPDF() {
    try {
      setLoadingPDF(true);
      await createPDF();

      setTimeout(() => {
        fetchPDF();
      }, 3500);

      setTimeout(() => {
        deletePDF();
        setLoadingPDF(false);
      }, 5000);

    } catch (err) {
      setLoadingPDF(false);
    }
  };

  // --- MODAL COMERCIAL
  function handleEditComercial(comercial: IComercial): void {
    setEditingComercial(comercial);
    toggleEditModalComercial();
  }

  function toggleEditModalComercial(): void {
    setEditModalComercialOpen(!editModalComercialOpen);
  }

  async function handleUpdateComercial(
    // politicaComercial: Omit<IComercial, 'moeda'>,                                          // OMIT -> omite os campos
    newPoliticaComercial: Pick<IComercial, 'precovendamoeda' | 'moeda' | 'codigocondicao'>,   // PICK -> apenas os campos
    confirmUpdatePedidosAbertos: boolean,
  ): Promise<void> {
    try {

      const response = await api.put<IComercial>('/comercial', {
        codigocliente: editingComercial.codigocliente,
        codigoproduto: editingComercial.codigoproduto,
        codigoemb: editingComercial.codigoemb,
        codigocondicao: editingComercial.codigocondicao,
        precovendamoeda: newPoliticaComercial.precovendamoeda,
        moeda: newPoliticaComercial.moeda,
        newcodigocondicao: newPoliticaComercial.codigocondicao,
      });

      if (confirmUpdatePedidosAbertos) {
        await api.put('/pedidovenda/updatepedidosabertos', {
          codigocliente: editingComercial.codigocliente,
          codigoproduto: editingComercial.codigoproduto,          
          precoVendaMoeda: newPoliticaComercial.precovendamoeda,
          moeda: newPoliticaComercial.moeda,
        });
      }

      setComercial(
        comercial.map(mappedComercial =>
          mappedComercial.codigocliente === editingComercial.codigocliente && 
          mappedComercial.codigoproduto === editingComercial.codigoproduto && 
          mappedComercial.codigoemb === editingComercial.codigoemb && 
          mappedComercial.codigocondicao === editingComercial.codigocondicao ? 
          { 
            ...mappedComercial, 
            ...response.data,
          } : mappedComercial,
        ),
      );
    } catch (err) {
      console.log(err);
    }
  }
  // --- MODAL COMERCIAL

  // --- MODAL ADD AGENDA
  function handleAddAgenda(agenda: IAgenda): void {
    setAddingAgenda(agenda);
    toggleAddModalAgenda();
  }

  function toggleAddModalAgenda(): void {
    setAddModalAgendaOpen(!addModalAgendaOpen);
  }

  async function handleCreateAgenda(
    newAgenda: Pick<IAgenda, 'dataPrevisao' | 'dataVisita' | 'assunto' | 'conclusao'>,
  ): Promise<void> {
    try {

      const response = await api.post<IAgenda>('/agenda', {
        codigoCliente: empresa?.codigoempresa,
        dataPrevisao: newAgenda.dataPrevisao,
        dataVisita: newAgenda.dataVisita,
        assunto: newAgenda.assunto,
        conclusao: newAgenda.conclusao,
      });

      setAgenda({ 
        ...response.data, 
        ...(response.data.dataPrevisao ? {
            dataPrevisao: format(new Date(
              new Date(response.data.dataPrevisao).getFullYear(), 
              new Date(response.data.dataPrevisao).getMonth(), 
              new Date(response.data.dataPrevisao).getDate()
            ), 'dd/MM/yyyy'),
          } : {}
        ),
        ...(response.data.dataVisita ? {
            dataVisita: format(new Date(
              new Date(response.data.dataVisita).getFullYear(), 
              new Date(response.data.dataVisita).getMonth(), 
              new Date(response.data.dataVisita).getDate()
            ), 'dd/MM/yyyy'),
          } : {}
        ),
      });
    } catch (err) {
      console.log(err);
    }
  }
  // --- MODAL ADD AGENDA

  // --- MODAL EDIT AGENDA
  function handleEditAgenda(agenda: IAgenda): void {

    setEditingAgenda({
      ...agenda,
      ...(agenda.dataPrevisao ? {
          previsaoDate: new Date(FormataStringData(agenda.dataPrevisao)),
        } : {}
      ),
      ...(agenda.dataVisita ? {
          visitaDate: new Date(FormataStringData(agenda.dataVisita)),
        } : {}
      ),
    });

    toggleEditModalAgenda();
  }

  function toggleEditModalAgenda(): void {
    setEditModalAgendaOpen(!editModalAgendaOpen);
  }

  async function handleUpdateAgenda(
    newAgenda: Pick<IAgenda, 'dataPrevisao' | 'dataVisita' | 'assunto' | 'conclusao' | 'previsaoDate' | 'visitaDate'>,
  ): Promise<void> {
    try {

      const response = await api.put<IAgenda>('/agenda', {
        id: editingAgenda.id,
        codigoCliente: editingAgenda.codigoCliente,
        assunto: newAgenda.assunto,
        conclusao: newAgenda.conclusao,
        ...(newAgenda.previsaoDate ? {
          dataPrevisao: format(newAgenda.previsaoDate, 'yyyy-MM-dd'),
        } : {}),
        ...(newAgenda.visitaDate ? {
          dataVisita: format(newAgenda.visitaDate, 'yyyy-MM-dd'), 
        } : {}),
      });

      setAgenda({
        ...response.data,
        ...(newAgenda.previsaoDate ? { 
          dataPrevisao: format(newAgenda.previsaoDate, 'dd/MM/yyyy') 
        } : {}),
        ...(newAgenda.visitaDate ? { 
          dataVisita: format(newAgenda.visitaDate, 'dd/MM/yyyy') 
        } : {}),
      });

    } catch (err) {
      console.log(err);
    }
  }
  // --- MODAL EDIT AGENDA

  function FormataStringData(data: string): string {
    var dia  = Number(data.split("/")[0]) + 1;
    var mes  = data.split("/")[1];
    var ano  = data.split("/")[2];
  
    //--> .slice(-2) garante formato com 2 dígitos
    return ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia.toString()).slice(-2);
  }

  if (loading) {
    return (
      <LoadingPage message='Buscando informações do cliente' />
    );
  }

  return (
    <Container>
      <Header headerType={'empresa'} empresa={empresa} search={location.state.searchClient} />

      <ModalEditComercial
        isOpen={editModalComercialOpen}
        setIsOpen={toggleEditModalComercial}
        editingComercial={editingComercial}
        handleUpdateComercial={handleUpdateComercial}
        moedas={moedas}
      />

      <Cadastro>
        <TituloPolitica>
          <h1>Política Comercial</h1>
          
          {comercial.length !== 0 && 
            <button type="button" onClick={() => { handleDownloadPDF() }}>
              
              <div className="text">Download</div>
              <div className="icon">
                  {!loadingPDF && <FiDownload size={22} />}
                  {loadingPDF && <ClipLoader size={22} color='#fff' loading/>}
              </div>
            </button>
          }
        </TituloPolitica>

        {comercial.length === 0 && <p>Sem registros...</p>}

        {comercial && 
          <ComercialContainer data-testid="comercial-list">
            {comercial.map((comercial, index) => (
              <ComercialComponent
                key={index}
                comercial={comercial}
                mediaTrimestre={mediaTrimestre.filter(media => media.CODIGOPRODUTO === comercial.codigoproduto)}
                mediaSemestre={mediaSemestre.filter(media => media.CODIGOPRODUTO === comercial.codigoproduto)}
                handleEditComercial={handleEditComercial}
              />
            ))}
          </ComercialContainer>
        }
      </Cadastro>

      <ModalAddAgenda
        isOpen={addModalAgendaOpen}
        setIsOpen={toggleAddModalAgenda}
        addingAgenda={addingAgenda}
        handleCreateAgenda={handleCreateAgenda}
      />

      <ModalEditAgenda
        isOpen={editModalAgendaOpen}
        setIsOpen={toggleEditModalAgenda}
        editingAgenda={editingAgenda}
        handleUpdateAgenda={handleUpdateAgenda}
      /> 

      <Cadastro>
        <Titulo>
          <h1>Última Visita</h1>
        </Titulo>

        {agenda !== undefined && 
          <AgendaContainer data-testid="agenda-list">
            <AgendaComponent
              key={agenda.id}
              agenda={agenda}
              handleAddAgenda={handleAddAgenda}
              handleEditAgenda={handleEditAgenda}
            />
          </AgendaContainer>
        }
      </Cadastro>        
    </Container>
  );
};

export default Comercial;
