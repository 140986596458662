import React, { useRef, useCallback } from 'react';
import { FiCheckSquare, FiCalendar } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Form } from './styles';
import Modal from '../Modal';
// import Input from '../Input';
import Textarea from '../Textarea';
import DatePicker from '../DatePicker';

import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

interface IAgenda {
   id: number;
   codigoCliente: string;
   dataPrevisao: string;
   dataVisita: string;
   assunto: string;
   conclusao: string;
   previsaoDate?: Date;
   visitaDate?: Date;
}

interface IEditAgendaData {
   dataPrevisao: string;
   dataVisita: string;
   assunto: string;
   conclusao: string;
   previsaoDate?: Date;
   visitaDate?: Date;
}

interface IModalProps {
   isOpen: boolean;
   setIsOpen: () => void;
   handleUpdateAgenda: (agenda: Pick<IAgenda, 'dataPrevisao' | 'dataVisita' | 'assunto' | 'conclusao' | 'previsaoDate' | 'visitaDate'>) => void;
   editingAgenda: IAgenda;
}

const ModalEditAgenda: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  editingAgenda,
  handleUpdateAgenda,
}) => {   
   const formRef = useRef<FormHandles>(null);
   const { addToast } = useToast();
   
   const handleSubmit = useCallback(
      async (data: IEditAgendaData, e) => {
         try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
               // dataPrevisao: Yup.date().typeError('Data da previsão inválida').required('Informe a data da previsão'),
               // dataVisita: Yup.date().typeError('Data da visita inválida').required('Informe a data da visita'),
               previsaoDate: Yup.date().typeError('Data da previsão inválida').required('Informe a data da previsão'),
               visitaDate: Yup.date().typeError('Data da visita inválida').required('Informe a data da visita'),
               assunto: Yup.string().required("Informe o assunto").max(250, "Máximo 250 caracteres"),
               conclusao: Yup.string().required("Informe a conclusão").max(500, "Máximo 500 caracteres"),
            });

            await schema.validate(data, {
               abortEarly: false,
            });

            handleUpdateAgenda(data);
            setIsOpen();

            addToast({
               type: 'success',
               title: 'Agenda Atualizada',
               description: 'Agenda atualizada com sucesso.',
            });
         } catch (err) {
            if (err instanceof Yup.ValidationError) {
               const errors = getValidationErrors(err); 
               formRef.current?.setErrors(errors); 
               return;
            }

            addToast({
               type: 'error',
               title: 'Erro na atualização',
               description: 'Ocorreu um erro ao atualizar a agenda, tente novamente.',
            });
         }
      },
      [handleUpdateAgenda, setIsOpen, addToast]
   );

   return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
         <Form 
            ref={formRef}
            // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            onSubmit={handleSubmit}
            initialData={{
               ...editingAgenda,
            }}
         >
            <h1>Cadastro de Agendamento</h1>

            <div onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
               <div>

                  <div className="class-input-date">
                     <DatePicker 
                        name="previsaoDate"
                        placeholderText="Data Previsão"
                        icon={FiCalendar}
                        containerStyle={{ minWidth: 140, width: 165, marginTop: 8, marginRight: 8 }}
                     /> 
                     <DatePicker 
                        name="visitaDate"
                        placeholderText="Data Visita"
                        icon={FiCalendar} 
                        containerStyle={{ minWidth: 140, width: 165, marginTop: 8, marginRight: 8 }}
                     /> 
                  </div>

                  <div className="class-textarea-text">
                     <Textarea name="assunto" placeholder="Assunto" /> 
                     <Textarea name="conclusao" placeholder="Conclusão" />
                  </div>
               </div>
            </div>

            <button type="submit" data-testid="edit-agenda-button" className="class-button-save">
               <div className="text">Salvar</div>
               <div className="icon">
                  <FiCheckSquare size={24} />
               </div>
            </button>
         </Form>
      </Modal>
   );
};

export default ModalEditAgenda; 