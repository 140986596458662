import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Form = styled(Unform)`
  padding: 15px 12px;
  display: flex;
  flex-direction: column;
  
  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  p {
    font-size: 18px;
    color: #373435;
    margin-bottom: 20px;
  }

  b {
    font-weight: 500;
    color: black;
  }

  div.class-input-date {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }

  button.class-button-list {
    margin-top: 30px;
    align-self: flex-end;
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #0F4C81;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding: 16px 24px;
    }
    .icon {
      display: flex;
      padding: 16px 16px;
      background: #1569b2;
      border-radius: 0 8px 8px 0;
      margin: 0 auto;
    }
  }

  @media(max-width: 980px) {
    padding: 14px 10px;
    h1 {
      font-size: 26px;
      line-height: 26px;
    }
    p {
      font-size: 16px;
    }
    button {
      margin-top: 25px;
    }
  } 

  @media(max-width: 620px) {
    padding: 12px 8px;
    h1 {
      font-size: 24px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
    }
    button {
      margin-top: 20px;
    }
  }   

  @media(max-width: 475px) {
    div.class-input-date {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  
  @media(max-width: 450px) {
    padding: 10px 5px;
    h1 {
      font-size: 20px;
      line-height: 20px;
    }
    p {
      font-size: 12px;
    }
    button {
      margin-top: 15px;
      .text {
        border-radius: 8px;
        padding: 10px 20px;
      }
      .icon {
        display: none;        
      }
    }
  }
`;