import React from 'react';
import { PropagateLoader } from 'react-spinners';

import logo from '../../assets/logo.png';
import { Container } from './styles';

interface ILoadingPage {
   message: string;
}

const LoadingPage = (props: ILoadingPage) => {
   return (
      <Container>
         <img src={logo} alt="Capuani" />
         <p>{props.message}</p>
         <PropagateLoader size={15} color='#0F4C81' loading/>
      </Container>
   );
}

export default LoadingPage;