import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Dashboard from '../pages/Dashboard';
import DashboardFinanceiro from '../pages/DashboardFinanceiro';
import Profile from '../pages/Profile';
import Users from '../pages/Users';
// import UpdateUser from '../pages/UpdateUser';
import Comercial from '../pages/Comercial';
import Amostras from '../pages/Amostras';
import Pedidos from '../pages/Pedidos';
import Faturas from '../pages/Faturas';
import DocumentosFaturasAbertas from '../pages/Documentos/FaturasAbertas';
import CaixaPrevisao from '../pages/Documentos/CaixaPrevisao';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/users" component={Users} isPrivate />
    <Route path="/comercial/:cliente" component={Comercial} isPrivate />
    <Route path="/amostras/:cliente" component={Amostras} isPrivate />
    <Route path="/pedidos/:cliente" component={Pedidos} isPrivate />
    <Route path="/faturas/:cliente" component={Faturas} isPrivate />
    <Route path="/dashboardfinanceiro" component={DashboardFinanceiro} isPrivate />
    <Route path="/documentos/:empresa/faturas/abertas" component={DocumentosFaturasAbertas} isPrivate />
    <Route path="/documentos/caixaprevisao" component={CaixaPrevisao} isPrivate />
  </Switch>
);

export default Routes;