import React, { useRef, useCallback } from 'react';
import { FiCheckSquare, FiCalendar } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { isAfter } from 'date-fns';
import * as Yup from 'yup';

import { Form } from './styles';
import Modal from '../Modal';
import DatePicker from '../DatePicker';

import getValidationErrors from '../../utils/getValidationErrors';
import Select from '../Select';

interface IRelCaixaPrevisao {
   numconta: string;
   datainicial: string;
   datafinal: string;
   saldobancario: number;
}
 
interface IRelCaixaPrevisaoFilter {
   numconta: string;
   saldobancario: number;
   datainicial: string;
   datafinal: string;
}

interface IBanco {
   numconta: string,
   nomefantasia: string,
}

interface IModalProps {
   isOpen: boolean;
   setIsOpen: () => void;
   filteringRelCaixaPrevisao: IRelCaixaPrevisao;
   handleListCaixaPrevisao: (caixaPrevisao: IRelCaixaPrevisaoFilter) => void;
   bancos: IBanco[];
}

const ModalRelCaixaPrevisao: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  filteringRelCaixaPrevisao,
  handleListCaixaPrevisao,
  bancos,
}) => {   
   const formRef = useRef<FormHandles>(null);

   const selectBancos = bancos.map(banco => {
      return {
         label: `${banco.numconta} -- ${banco.nomefantasia}`,
         value: banco.numconta,
      };
   });

   const handleSubmit = useCallback(
      async (data: IRelCaixaPrevisaoFilter, e) => {
         try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
               numconta: Yup.string().required("Selecione uma conta"),
               datainicial: Yup.date().typeError('Data inicial inválida').required('Informe a data inicial do relatório'),
               datafinal: Yup.date()
                  .typeError('Data final inválida')
                  .required('Informe a data final do relatório')
                  .test('Datas', 'Data Final deve ser maior ou igual a Data Inicial', (value) => {
                     const dataInicial = new Date(data.datainicial);
                     const dataFinal = new Date(value);
                     return !dataInicial || !value || isAfter(dataFinal, dataInicial);
                  }),
            });

            await schema.validate(data, {
               abortEarly: false,
            });

            filteringRelCaixaPrevisao.numconta = data.numconta;
            filteringRelCaixaPrevisao.datainicial = data.datainicial;
            filteringRelCaixaPrevisao.datafinal = data.datafinal;
            filteringRelCaixaPrevisao.saldobancario = data.saldobancario;

            handleListCaixaPrevisao(data);
            setIsOpen();
         } catch (err) {
            if (err instanceof Yup.ValidationError) {
               const errors = getValidationErrors(err); 
               formRef.current?.setErrors(errors); 
               return;
            }
         }
      },
      [handleListCaixaPrevisao, setIsOpen, filteringRelCaixaPrevisao]
   );
   
   return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
         <Form 
            ref={formRef}
            // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            onSubmit={handleSubmit}
            initialData={filteringRelCaixaPrevisao}
         >
            <h1>Relatório Caixa Previsão</h1>
            
            <div onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
               <div>
                  <div className="class-input-date">
                     <DatePicker 
                        name="datainicial" 
                        placeholderText="Data Inicial"
                        icon={FiCalendar}
                        containerStyle={{ minWidth: 140, width: 165, marginTop: 8, marginRight: 8 }}
                     /> 
                     <DatePicker 
                        name="datafinal" 
                        placeholderText="Data Final"
                        icon={FiCalendar} 
                        containerStyle={{ minWidth: 140, width: 165, marginTop: 8, marginRight: 8 }}
                     /> 
                  </div>
                  <Select
                     name="numconta"
                     options={selectBancos}
                  />
               </div>
            </div>

            {/* elemento criado para não cortar o calendário */}
            <div style={{ height: 80 }}></div>

            <button type="submit" data-testid="list-caixa-previsao-button" className="class-button-list">
               <div className="text">Filtrar</div>
               <div className="icon">
                  <FiCheckSquare size={24} />
               </div>
            </button>
         </Form>
      </Modal>
   );
};

export default ModalRelCaixaPrevisao;