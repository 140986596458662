import styled from 'styled-components';
import { shade } from 'polished';
import { Form as Unform } from '@unform/web';

interface IProps {
  isActive?: number;
}

export const Form = styled(Unform)`
  padding: 2px 0px;
  display: flex;
  justify-content: flex-end;

  div{
    width: 100%;
  }

  button {
    display: flex;
    margin-left: 5px;
    font-weight: 500;
    border-radius: 8px;
    border: 0;
    background: #0F4C81;
    color: #fff;
    .text {
      padding: 16px 24px;
    }
    .icon {
      display: flex;
      padding: 16px 16px;
      background: #1569b2;
      border-radius: 8px;
      margin: 0 auto;
    }
  }
`;

export const Container = styled.div`
  h1 {
    font-size: 24px;
    margin-top: 10px;

    @media(max-width: 450px) {
      font-size: 20px;
      margin-top: 5px;
    }
  }
`;
export const Pesquisa = styled.div`
  width: 95%;
  max-width: 1120px;
  margin: 0 auto;
`;

export const Cadastro = styled.div`
  width: 95%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 15px;

  @media(max-width: 430px) {
    padding: 10px 0;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 10px;
  }
`;

export const Empresa = styled.div<IProps>`
  align-items: center;
  font-size: 18px;
  border-radius: 8px;
  background: #f0f0f5;

  span {
    color: #373435;
  }

  section.body {
    display: flex;
    align-items: center;
    
    padding: 10px;
    div {
      display: flex;
      align-items: center;
      padding: 3px;
    }
    svg {
      margin-left: auto;
      color: ${props => props.isActive === 1 ? '#91be4a' : '#8a0000'};
      width: 18px;
      height: 18px;
    }
    p {
      color: #373435;
      & + p {
        margin-left: 25px;
      }

      @media(max-width: 980px) {
        & + p {
          margin-left: 18px;
        }
      }    
      @media(max-width: 620px) {
        & + p {
          margin-left: 16px;
        }
      }    
      @media(max-width: 450px) {
        & + p {
          margin-left: 14px;
        }
      }
    }
    b {
      font-weight: 500;
      color: black;
    }
  }

  section.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: #e4e4eb;
    border-radius: 0px 0px 8px 8px;

    div.icon-container {
      display: flex;

      button {
        display: flex;
        background: #f0f0f5;
        border-radius: 8px;        
        border: none;
        transition: 0.2s;

        &:hover {
          background: ${shade(0.1, '#0F4C81')};
          p {
            color: #f0f0f5;
          }
        }

        a {
          padding: 5px;
          border-radius: 8px;
          text-decoration: none;
          font-size: 14px;
          color: #3d3d4d;

          @media(max-width: 980px) {
            // padding: 6px;
            // font-size: 12px;
          }    
          @media(max-width: 620px) {
            padding: 6px;
            font-size: 12px;
          }    
          @media(max-width: 450px) {
             padding: 4px;
             // font-size: 10px;
          }
        }

        & + button {
          margin-left: 6px;
        }
      }
    }
    div.availability-container {
      display: flex;
      align-items: center;
      p {
        color: #3d3d4d;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 30px;
        margin-left: 12px;
        & input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #aa2222;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 16px;
          &:before {
            position: absolute;
            content: '';
            height: 26px;
            width: 26px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 16px;
          }
        }
        input:checked + .slider {
          background-color: #0F4C81;
        }
        input:checked + .slider:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }
    }
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 25px;
    margin-top: 100px;
    margin-bottom: 30px;
  }
`;