import styled from 'styled-components';
import { shade } from 'polished';

interface IComercialProps {
  ativo: boolean;
}

export const Container = styled.div<IComercialProps>`
  background: #f0f0f5;
  border-radius: 8px;

  section.body {
    padding: 5px 10px;
    div {
      display: flex;
      align-items: center;
      padding: 3px;
    }
    p {
      color: #373435;
      & + p {
        margin-left: 25px;
      }

      @media(max-width: 980px) {
        & + p {
          margin-left: 18px;
        }
      }    
      @media(max-width: 620px) {
        & + p {
          margin-left: 16px;
        }
      }    
      @media(max-width: 450px) {
        & + p {
          margin-left: 14px;
        }
      }
    }
    b {
      font-weight: 500;
      color: black;
    }
  }

  section.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: #e4e4eb;
    border-radius: 0px 0px 8px 8px;

    div.icon-container {
      display: flex;
      button {
        background: #f0f0f5;
        padding: 8px;
        border-radius: 8px;
        display: flex;
        border: none;
        transition: 0.1s;
        svg {
          color: #3d3d4d;
        }
        &:hover {
          svg {
            color: ${shade(0.2, '#0F4C81')};
          }
        }
        & + button {
          margin-left: 6px;
        }

        @media(max-width: 980px) {
          padding: 6px;
        }    
        @media(max-width: 620px) {
          padding: 4px;
        }    
        @media(max-width: 450px) {
          padding: 2px;
          background: transparent;
        }
      }
    }
    div.availability-container {
      display: flex;
      align-items: center;
      p {
        color: #3d3d4d;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 30px;
        margin-left: 12px;
        & input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #aa2222;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 16px;
          &:before {
            position: absolute;
            content: '';
            height: 26px;
            width: 26px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 16px;
          }
        }
        input:checked + .slider {
          background-color: #0F4C81;
        }
        // input:focus + .slider {
        //   box-shadow: 0 0 1px #2196f3;
        // }
        input:checked + .slider:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }
    }
  }
`;