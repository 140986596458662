import React from 'react';
import { MenuNav, MenuDiv, MenuList, MenuItem, MenuLink } from './styles';

interface MenuItem {
  title: string;
  path: string;
  visible: boolean;
}

interface MenuProps {
  items: MenuItem[];
}

const Menu: React.FC<MenuProps> = ({ items }) => {
  const visibleItems = items.filter(item => item.visible);

  if (visibleItems.length === 0) {
    return null; // Não renderiza nada se não houver opções visíveis
  }

  return (
    <MenuNav>
      <MenuDiv>
      <MenuList>
        {visibleItems.map(item => (
          <MenuItem key={item.path}>
            <MenuLink to={item.path}>{item.title}</MenuLink>
          </MenuItem>
        ))}
      </MenuList>
      </MenuDiv>
    </MenuNav>
  );
};

export default Menu;
