import React from 'react';
import { Container } from './styles';

interface IUser {
  id: string;
  name: string;
  email: string;
  autonum: number;
  codigorepre: string;
  USERCAPUANI: {
    nome: string;
  }
}

interface IProps {
  user: IUser;
  handleDeleteUser: (id: string) => void;
  handleEditUser: (user: IUser) => void;
}

const User: React.FC<IProps> = ({
  user,
  handleDeleteUser,
  handleEditUser,
}: IProps) => {
  function setIdDeleteUser(): void {
    handleDeleteUser(user.id);
  }

  function setEditingUser(): void {
    handleEditUser(user);
  }

  return (
    <>
      <Container user={user}>
        {user !== undefined && Object.keys(user).length !== 0 ? 
          <section className="body">
            <div>
              <p><b>ID: </b>{user.id}</p>
            </div>
            <div>
              <p><b>Usuário: </b>{user.name}</p>
            </div>
            <div>
              <p><b>Email: </b>{user.email}</p>
            </div>
            <div>
              <p><b>Funcionário: </b>{user.autonum} - {user.USERCAPUANI && user.USERCAPUANI.nome}</p>
            </div>
          </section>
        :
          <div className="div-empty">
            <p>Sem registros...</p>
          </div>
        }

        <section className="footer">
          <div className="button-container">
            <button
              type="button"
              className="edit-button"
              onClick={() => setEditingUser()}
              data-testid={`user-edit-${user.id}`}
            >
              <div className="text">Editar</div>
            </button>

            {user !== undefined && Object.keys(user).length !== 0 &&
              <button
                type="button"
                className="delete-button"
                onClick={() => setIdDeleteUser()}
                data-testid={`user-delete-${user.id}`}
              >
                <div className="text">Excluir</div>
              </button>
            }
          </div>
        </section>      
      </Container>
    </>
  );
};

export default User;