import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FiPower, FiArrowLeftCircle } from 'react-icons/fi';
import { Container, HeaderContent } from './styles';

import logo from '../../assets/logo.png';
import { useAuth } from '../../hooks/auth';

interface IEmpresa {
  codigoempresa: string;
	nomefantasia: string;
}

interface IHeaderProps {
  headerType: 'dashboard' | 'empresa' | 'profile' | 'financeiro' | 'financeiro-sem-empresa';
  empresa?: IEmpresa;
  search?: string;
}

const Header: React.FC<IHeaderProps> = ({ headerType, empresa, search }) => {

  const history = useHistory();
  const { user, signOut } = useAuth();

  return (
    <Container>
      <HeaderContent>
        <img src={logo} alt="Capuani" onClick={() => { 
          history.push({
            pathname: '/dashboard',
            state: {
              searchClient: ''
          }}) 
        }}/>

        {headerType === 'dashboard' &&  
          <nav>
            <div style={{ flexDirection: 'column' }}>
              <span>Bem-vindo,</span>
              <Link to="/profile">
                <strong>{user.name}</strong>
              </Link>
            </div>

            <button type="button" onClick={signOut}>
              <FiPower />
            </button>
          </nav>   
        }

        {headerType === 'profile' &&  
          <nav>
            <button type="button">
              <Link to={{ 
                pathname:  "/dashboard", 
                state: {
                  searchClient: '',
                }
              }}>
                <FiArrowLeftCircle />
              </Link>
            </button>
          </nav>   
        }

        {headerType === 'empresa' &&
          <nav>
            <div>
              <span>Cliente:</span>
              <span><strong>{empresa && (empresa.codigoempresa + ' - ' + empresa.nomefantasia)}</strong></span>
            </div>

            <button type="button">
              <Link to={{ 
                pathname: '/dashboard',
                state: {
                  searchClient: search,
                }
              }}>
                <FiArrowLeftCircle />
              </Link>
            </button>
          </nav>   
        }

        {headerType === 'financeiro' &&
          <nav>
            {empresa &&
              <div>
                <span>Empresa:</span>
                <span><strong>{empresa.codigoempresa + ' - ' + empresa.nomefantasia}</strong></span>
              </div>
            }

            <button type="button">
              <Link to={{ 
                pathname: '/dashboardfinanceiro',
                state: {
                  searchClient: search,
                }
              }}>
                <FiArrowLeftCircle />
              </Link>
            </button>
          </nav>   
        }

        {headerType === 'financeiro-sem-empresa' &&
          <nav>
            <button type="button">
              <Link to={{ 
                pathname: '/dashboardfinanceiro',
                state: {
                  searchClient: '',
                }
              }}>
                <FiArrowLeftCircle />
              </Link>
            </button>
          </nav>   
        }
      </HeaderContent>
    </Container>
  );
};

export default Header;