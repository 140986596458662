import React from 'react';
import { FiFilePlus, FiEdit } from 'react-icons/fi';
import { Container } from './styles';

interface IAgenda {
  id: number;
  codigoCliente: string;
  dataPrevisao: string;
  dataVisita: string;
  assunto: string;
  conclusao: string;
}

interface IProps {
  agenda: IAgenda;
  handleAddAgenda: (agenda: IAgenda) => void;
  handleEditAgenda: (agenda: IAgenda) => void;
}

const Agenda: React.FC<IProps> = ({
  agenda,
  handleAddAgenda,
  handleEditAgenda,
}: IProps) => {
 
  function setAddingAgenda(): void {
    handleAddAgenda(agenda);
  }

  function setEditingAgenda(): void {
    handleEditAgenda(agenda);
  }

  return (
    <Container agenda={agenda}>
      {agenda !== undefined && Object.keys(agenda).length !== 0 ? 
        <section className="body">
          <div>
            <p><b>Data da Previsão: </b>{agenda.dataPrevisao}</p>
            <p><b>Data da Visita: </b>{agenda.dataVisita}</p>
          </div>
          <div>
            <p><b>Assunto: </b>{agenda.assunto}</p>
          </div>
          <div>
            <p><b>Conclusão: </b>{agenda.conclusao}</p>
          </div>
        </section>
      :
        <div className="div-empty">
          <p>Sem registros...</p>
        </div>
      }

      <section className="footer">
        <div className="icon-container">
          <button
            type="button"
            className="icon"
            onClick={() => setAddingAgenda()}
            data-testid={`add-agenda-${agenda.codigoCliente}`}
          >
            <FiFilePlus size={22} />
          </button>

          {agenda !== undefined && Object.keys(agenda).length !== 0 &&
            <button
              type="button"
              className="icon"
              onClick={() => setEditingAgenda()}
              data-testid={`edit-comercial-${agenda.codigoCliente}`}
            >
              <FiEdit size={20} />
            </button>
          }
        </div>
      </section>



      
    </Container>
  );
};

export default Agenda;