import React, { useRef, useCallback, useState } from 'react';
import { FiCheckSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Form } from './styles';
import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import ConfirmDialog from '../ConfirmDialog';

import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

interface IUser {
   id: string;
   name: string;
   email: string;
   autonum: number;
   codigorepre: string;
   password?: string;
   USERCAPUANI: {
     nome: string;
   }
 }

interface IFuncionario {
   autonum: number;
   nome: string;
}

interface IAddUserData {
   id: string;
   name: string;
   email: string;
   autonum: number;
   password: string;
}

interface IModalProps {
   isOpen: boolean;
   setIsOpen: () => void;
   handleCreateUser: (user: Pick<IUser, 'id' | 'name' | 'email' | 'autonum' | 'password'>) => void;
   // addingUser: IUser;
   funcionarios: IFuncionario[],
}

const ModalAddUser: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  // addingUser,
  handleCreateUser,
  funcionarios,
}) => {   
   const formRef = useRef<FormHandles>(null);
   const { addToast } = useToast();

   const [dialogUserOpen, setDialogUserOpen] = useState(false);
   const [, setConfirm] = useState(false);

   const selectFuncionarios = funcionarios.map(funcionario => {
      return {
         label: funcionario.nome,
         value: funcionario.autonum.toString(),
      };
   });

   const handleSubmit = useCallback(
      async (data: IAddUserData, e) => {
         try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
               name: Yup.string().required("Nome inválido"),
               email: Yup.string().email().required("Email inválido"),
               autonum: Yup.number().positive().required(),
               password: Yup.string().required(),
               // codigorepre: Yup.string().nullable(true).matches(/^[0-9]+$/, "Informe apenas dígitos"),
            });

            await schema.validate(data, {
               abortEarly: false,
            });

            handleCreateUser(data);
            setIsOpen();

            addToast({
               type: 'success',
               title: 'Usuário Cadastrado',
               description: 'Usuário cadastrado com sucesso.',
            });
         } catch (err) {
            if (err instanceof Yup.ValidationError) {
               const errors = getValidationErrors(err); 
               formRef.current?.setErrors(errors); 
               return;
            }

            addToast({
               type: 'error',
               title: 'Erro',
               description: 'Ocorreu um erro ao salvar o usuário, tente novamente.',
            });
         }
      },
      [handleCreateUser, setIsOpen, addToast]
   );
   
   function handleDialog(): void {
      setDialogUserOpen(!dialogUserOpen);
      setConfirm(false);
   }
  
   const handleDialogClose = () => {
      setDialogUserOpen(false);
   };

   const handleDialogNo = async () => {
      setDialogUserOpen(false);
      await setConfirm(false);
   };

   const handleDialogYes = async () => { 
      setDialogUserOpen(false);
      await setConfirm(true);
      formRef.current?.submitForm();
   };

   return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
         <Form 
            ref={formRef}
            onSubmit={handleSubmit}
            // initialData={addingUser}
         >
            <h1>Cadastro de Usuários</h1>
            {/* <p><b>Nome: </b>{editingUser.name}</p> */}

            <div onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
               <Input
                  name="name"
                  placeholder="Nome"
               /> 
               <Input
                  name="email"
                  placeholder="Email"
               /> 
               <Select
                  name="autonum"
                  options={selectFuncionarios}
               />
               <Input
                  name="password"
                  // icon={FiLock}
                  type="password"
                  placeholder="Senha"
               /> 
            </div>

            <button type="button" onClick={handleDialog} data-testid="add-user-button">
               <div className="text">Salvar</div>
               <div className="icon">
                  <FiCheckSquare size={24} />
               </div>
            </button>
         </Form>

         <ConfirmDialog
            isOpen={dialogUserOpen}
            question='Confirma o cadastro do usuário?'
            // description1='NÃO: altera apenas a política comercial selecionada.'
            // description2='SIM: altera a política comercial selecionada e os pedidos não faturados.'
            handleClose={handleDialogClose}
            handleNo={handleDialogNo}
            handleYes={handleDialogYes}
         />
      </Modal>
   );
};

export default ModalAddUser;