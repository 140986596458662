import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #E4E4EB;
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid #E4E4EB;
  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #d91c1c;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #0F4C81;
      border-color: #0F4C81;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #0F4C81;
    `}

  textarea {
    flex: 1;
    width: 100%;
    background: transparent;
    border: 0;
    color: #373435;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }

  @media(max-width: 980px) {
    padding: 14px;
  }    
  @media(max-width: 620px) {
    padding: 12px;
  }    
  @media(max-width: 450px) {
    padding: 10px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #d91c1c;
    color: #fff;

    &::before {
      border-color: #d91c1c transparent;
    }
  }
`;
