import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FiX, FiCheck, FiSearch } from 'react-icons/fi';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, Pesquisa, Cadastro, Empresa, ContainerLoading, Form } from './styles';
import Input from '../../components/Input';

// import { useAuth } from '~/hooks/auth';
// import api from '~/services/api';
import api from '../../services/api';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { isUserAdmin, isUserFinanceiro } from '../../utils/accessControl';
import { useAuth } from '../../hooks/auth';

interface IEmpresa {
  codigoempresa: string;
  nomefantasia: string;
  flag_ativo: number;
}

interface ISearchData {
  pesquisa: string;
}

interface ILocation {
  searchClient: string;
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [search, setSearch] = useState('');

  const formRef = useRef<FormHandles>(null);
  const location = useLocation<ILocation>();
  const history = useHistory();
  const { user } = useAuth();

  if (location.state) {
    const { searchClient } = location.state;

    if (searchClient) {
      let keyword = location.state.searchClient;
      location.state.searchClient = ''; 
      setSearch(keyword);
    }
  }

  useEffect(() => {
    async function getClientes() {
      try {
        setLoading(true);

        const response = await api.get<IEmpresa[]>('/empresas/clientes', {
          headers: {
            pesquisa: search,
          }
        });

        setEmpresas(response.data);

      } finally {
        setLoading(false);
      }
    }
    
    getClientes();
  }, [search]);

  const handleSearch = useCallback(
    async (data: ISearchData, e) => {
       try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          pesquisa: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!data.pesquisa) {
          history.replace({ ...location, state: { searchClient: '' }});
        }

        setSearch(data.pesquisa);

        setLoading(false);
      } finally {
        // setLoading(false);
      }
    }, [history, location]
  );

  const menuItems = [
    { title: 'Usuários', path: '/users', visible: isUserAdmin(user.email) },
    { title: 'Financeiro', path: '/dashboardfinanceiro', visible: isUserAdmin(user.email) || isUserFinanceiro(user.email) },
  ];

  return (  
    <Container>
      <Header headerType={'dashboard'}/>

      <Menu items={menuItems} />

      <Pesquisa>
        <h1>Cadastro de Clientes</h1>

        <Form 
          ref={formRef}
          onSubmit={handleSearch}
        >
          <Input 
            name="pesquisa" 
            placeholder="pesquisar..." 
            defaultValue={search}
            icon={FiSearch}
            containerStyle={{ border: 2, borderStyle: 'solid', borderColor: "#0F4C81" }} 
          /> 
        </Form>
      </Pesquisa>

      {loading ?
        <ContainerLoading>
          <p>Buscando clientes</p>
          <PulseLoader size={12} margin={5} color='#0F4C81' loading/>
        </ContainerLoading>
      :
        <Cadastro>
          {empresas && empresas.map(empresa => (
            <Empresa isActive={empresa.flag_ativo} key={empresa.codigoempresa}>
              <section className="body">
                <p>{empresa.codigoempresa} - {empresa.nomefantasia}</p>

                {empresa.flag_ativo ? 
                  <FiCheck />
                :
                  <FiX />
                }
              </section>

              <section className="footer">
                <div className="icon-container">
                  
                  <button type="button" title="Política Comercial">
                    <Link to={{ 
                      pathname: `/comercial/${empresa.codigoempresa}`, 
                      state: {
                        searchClient: search,
                      }
                    }}>
                      <p>Comercial</p>
                    </Link>
                  </button>

                  <button type="button" title="Projetos de Amostras">
                    <Link to={{ 
                      pathname: `/amostras/${empresa.codigoempresa}`, 
                      state: {
                        searchClient: search,
                      }
                    }}>
                      <p>Projetos</p>
                    </Link>
                  </button>

                  <button type="button" title="Pedidos de Vendas">
                    <Link to={{ 
                      pathname: `/pedidos/${empresa.codigoempresa}`, 
                      state: {
                        searchClient: search,
                      }
                    }}>
                      <p>Pedidos</p>
                    </Link>
                  </button>

                  <button type="button" title="Notas Fiscais">
                    <Link to={{ 
                      pathname: `/faturas/${empresa.codigoempresa}`, 
                      state: {
                        searchClient: search,
                      }
                    }}>
                      <p>Faturas</p>
                    </Link>
                  </button>
                  
                </div>
              </section>
            </Empresa>
          ))}

        </Cadastro>
      }
    </Container>
  );
};

export default Dashboard;
