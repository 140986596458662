import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { FiDownload, FiFilter } from 'react-icons/fi';
import { Table, TableRow } from '@material-ui/core';

import { ClipLoader } from 'react-spinners';
import { saveAs } from 'file-saver';

import Header from '../../../components/Header';
import LoadingPage from '../../../components/LoadingPage';

import { 
  Container, Cadastro, TituloPolitica, StyledTableContainer, StyledTableCell, StrongHeader, StyledTableBody, StyledTableHead,
  Box, CampoLabel, CampoValor, ContainerRow
} from './styles';

// import api from '~/services/api';
import api from '../../../services/api';
import ModalRelCaixaPrevisao from '../../../components/ModalRelCaixaPrevisao';
import { useToast } from '../../../hooks/toast';

interface IRelCaixaPrevisao {
  numconta: string;
  datainicial: string;
  datafinal: string;
  saldobancario: number;
  nomefantasia: string;
}

interface IDocumentoCaixaPrevisao {
  data_vencimento: string;
  entradas: number;
  saidas: number;
  saldo_diario: number;
  saldo_acumulado: number;
  valor_total_entradas: number;
  valor_total_saidas: number;
}

interface IBanco {
  numconta: string,
	codbanco: string,
	agencia: string,
	tipoconta: string,
	nomebanco: string,
	nomefantasia: string,
	saldobancario: number,
}

interface IRelCaixaPrevisaoFilter {
  numconta: string;
  saldobancario: number;
  datainicial: string;
  datafinal: string;
}

interface IBanco {
  numconta: string;
  nomefantasia: string;
}

interface ISaldoBancoCredito {
  SALDO: number;
}

interface ISaldoBancoDebito {
  SALDO: number;
}

const DocumentosCaixaPrevisao: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [relCaixaPrevisao, setRelCaixaPrevisao] = useState<IDocumentoCaixaPrevisao[]>([]);
  const [bancos, setBancos] = useState<IBanco[]>([]);

  const [filteringRelCaixaPrevisao, setFilteringRelCaixaPrevisao] = useState<IRelCaixaPrevisao>({} as IRelCaixaPrevisao);
  const [modalRelCaixaPrevisaoOpen, setModalCaixaPrevisaoOpen] = useState(true);

  const { addToast } = useToast();

  useEffect(() => { 
    async function getFilters() {
      try {
        setLoading(true);

        const resBancos = await api.get<IBanco[]>(`/bancos/tipoconta?tipoconta=${encodeURIComponent('CONTA CORRENTE')}`);
        setBancos(resBancos.data);

      //} catch (error) {
      //  alert('Cliente inválido!');
      } finally {
        setLoading(false);
      }
    }
    
    getFilters();
  }, []);

  async function createPDF() {
    await api.post('/documentos/create-pdf-caixa-previsao', { 
      // Passando BODY PARAMS no POST
      numconta: filteringRelCaixaPrevisao.numconta,
      saldobancario: filteringRelCaixaPrevisao.saldobancario,
      datainicial: format(new Date(
        new Date(filteringRelCaixaPrevisao.datainicial).getUTCFullYear(), 
        new Date(filteringRelCaixaPrevisao.datainicial).getUTCMonth(), 
        new Date(filteringRelCaixaPrevisao.datainicial).getUTCDate()
      ), 'dd/MM/yyyy'),
      datafinal: format(new Date(
        new Date(filteringRelCaixaPrevisao.datafinal).getUTCFullYear(), 
        new Date(filteringRelCaixaPrevisao.datafinal).getUTCMonth(), 
        new Date(filteringRelCaixaPrevisao.datafinal).getUTCDate()
      ), 'dd/MM/yyyy'),
    });
  };

  async function fetchPDF() {
    try {
      const responsePDF = await api.get('/documentos/fetch-pdf-caixa-previsao', { 
        // GET com BODY PARAMS
        headers: {
          numconta: filteringRelCaixaPrevisao.numconta
        },
        responseType: 'blob',
      });

      const pdfBlob = new Blob([responsePDF.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `CAIXA-PREVISAO_${filteringRelCaixaPrevisao.numconta.replace(/\s+/g, "-")}.pdf`);

      addToast({
        type: 'success',
        title: 'Download concluído' ,
        description: 'Download do arquivo PDF realizado com sucesso.',
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Erro no download',
        description: 'Ocorreu um erro ao realizar o download do arquivo PDF, tente novamente.',
      });
    }
  };

  async function deletePDF() {
    await api.delete('/documentos/delete-pdf-caixa-previsao', { 
      // DELETE com BODY PARAMS
      data: {
        numconta: filteringRelCaixaPrevisao.numconta
      }
    });
  };

  async function handleDownloadPDF() {
    try {
      setLoadingPDF(true);
      await createPDF();

      setTimeout(() => {
        fetchPDF();
      }, 5000);

      setTimeout(() => {
        deletePDF();
        setLoadingPDF(false);
      }, 7000);

    } catch (err) {
      setLoadingPDF(false);
    }
  };

  // --- MODAL RELATÓRIO PREVISÃO CAIXA
  function handleFilterCaixaPrevisao(relCaixaPrevisao: IRelCaixaPrevisao): void {
    setFilteringRelCaixaPrevisao(relCaixaPrevisao);
    toggleModalRelCaixaPrevisao();
  }

  function toggleModalRelCaixaPrevisao(): void {
    setModalCaixaPrevisaoOpen(!modalRelCaixaPrevisaoOpen);
  }

  async function handleListCaixaPrevisao(
    { numconta, saldobancario, datainicial, datafinal }: IRelCaixaPrevisaoFilter,
  ): Promise<void> {
    try {
      setLoading(true);

      const responseBanco = await api.get<IBanco>(`/bancos/conta?numconta=${encodeURIComponent(numconta)}`);
      saldobancario = responseBanco.data.saldobancario;
      var saldoCredito = 0;
      var saldoDebito = 0;

      const stringDataInicial = FormataStringData(datainicial);
      const stringDataFinal = FormataStringData(datafinal);

      const dataInicial = new Date(
        parseInt(stringDataInicial.split('/')[2]),      // Ano
        parseInt(stringDataInicial.split('/')[1]) - 1,  // Mês (JS o mês começa do zero)
        parseInt(stringDataInicial.split('/')[0]),      // Dia
      );
      // Regra de negócio definida no sistema da Capuani (FrmFluxoCaixaPrevisao.vb)
      const dataLimite = new Date("2020-01-01");

      if (dataInicial > dataLimite) {
        const responseSaldoCredito = await api.get<ISaldoBancoCredito>(`/documentos/calcular/banco/credito?contapagocred=${encodeURIComponent(numconta)}&datainicial=${encodeURIComponent(stringDataInicial)}`);
        saldoCredito = responseSaldoCredito.data.SALDO;

        const responseSaldoDebito = await api.get<ISaldoBancoDebito>(`/documentos/calcular/banco/debito?contapagodeb=${encodeURIComponent(numconta)}&datainicial=${encodeURIComponent(stringDataInicial)}`);
        saldoDebito =  responseSaldoDebito.data.SALDO;
      }

      saldobancario = saldobancario + (saldoCredito - saldoDebito);
      saldobancario = Math.round(saldobancario * 100) / 100;  // arredondamento

      setFilteringRelCaixaPrevisao({
        numconta: numconta,
        datainicial: datainicial,
        datafinal: datafinal,
        saldobancario: saldobancario,
        nomefantasia: responseBanco.data.nomefantasia
      })

      setRelCaixaPrevisao([]);

      const responseCaixaPrevisao = await api.get<IDocumentoCaixaPrevisao[]>(`/documentos/caixaprevisao?numconta=${encodeURIComponent(numconta)}&saldobancario=${encodeURIComponent(saldobancario)}&datainicial=${encodeURIComponent(stringDataInicial)}&datafinal=${encodeURIComponent(stringDataFinal)}`);
      const formattedResponse = responseCaixaPrevisao.data.map(registro => {
        return {
          ...registro,
          data_vencimento: format(new Date(
            new Date(registro.data_vencimento).getUTCFullYear(), 
            new Date(registro.data_vencimento).getUTCMonth(), 
            new Date(registro.data_vencimento).getUTCDate()
          ), 'dd/MM/yyyy'),
        };
      });
      setRelCaixaPrevisao((prevRegistros) => [...prevRegistros, ...formattedResponse]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }
  // --- MODAL RELATÓRIO PREVISÃO CAIXA

  function FormataStringData(data: string): string {
    const dateParam = new Date(data);

    const dia = dateParam.getDate().toString().padStart(2, '0');
    const mes = (dateParam.getMonth() + 1).toString().padStart(2, '0');  // Os meses começam do zero, por isso +1
    const ano = dateParam.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  if (loading) {
    return (
      <LoadingPage message='Buscando informações' />
    );
  }

  return (
    <Container>
      {/* <Header headerType={'profile'} /> */}
      <Header headerType={'financeiro-sem-empresa'} />

      <Cadastro>
        <TituloPolitica>
          <h1>Relatório Previsão Caixa</h1>
          
          <div>
            {relCaixaPrevisao.length !== 0 && 
              <button type="button" onClick={() => { handleDownloadPDF() }}>
                <div className="text">Download</div>
                <div className="icon">
                    {!loadingPDF && <FiDownload size={22} />}
                    {loadingPDF && <ClipLoader size={22} color='#fff' loading/>}
                </div>
              </button>
            }

            <button type="button" onClick={() => { handleFilterCaixaPrevisao(filteringRelCaixaPrevisao) }}>
              <div className="text">Filtro</div>
              <div className="icon">
                  <FiFilter size={22} />
              </div>
            </button>
          </div>          
        </TituloPolitica>

        {relCaixaPrevisao.length === 0 && <p>Sem registros...</p>}

        {relCaixaPrevisao.length !== 0 && 
          <>
            <ContainerRow>
              <Box>
                <CampoLabel>
                  Conta: <CampoValor>{`${filteringRelCaixaPrevisao.numconta} - ${filteringRelCaixaPrevisao.nomefantasia}`}</CampoValor>
                </CampoLabel>
              </Box>
            </ContainerRow>
            <ContainerRow>
              <Box>
                <CampoLabel>
                  Data inicial: <CampoValor>{FormataStringData(filteringRelCaixaPrevisao.datainicial)}</CampoValor>
                </CampoLabel>
              </Box>
              <Box>
                <CampoLabel>
                  Data final: <CampoValor>{FormataStringData(filteringRelCaixaPrevisao.datafinal)}</CampoValor>
                </CampoLabel>
              </Box>
              <Box>
                <CampoLabel>
                  Saldo inicial: <CampoValor>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(filteringRelCaixaPrevisao.saldobancario)}</CampoValor>
                </CampoLabel>
              </Box>
            </ContainerRow>

            <StyledTableContainer>  
              <Table size="small">
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell align="left"><StrongHeader>Data Vencimento</StrongHeader></StyledTableCell>
                    <StyledTableCell align="right"><StrongHeader>Entradas</StrongHeader></StyledTableCell>
                    <StyledTableCell align="right"><StrongHeader>Saídas</StrongHeader></StyledTableCell>
                    <StyledTableCell align="right"><StrongHeader>Saldo Diário</StrongHeader></StyledTableCell>
                    <StyledTableCell align="right"><StrongHeader>Saldo Acumulado</StrongHeader></StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {relCaixaPrevisao.map((documento, index) => (
                    <TableRow key={index}>
                      <StyledTableCell align="left" width="12%">{documento.data_vencimento}</StyledTableCell>
                      <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(documento.entradas)}</StyledTableCell>
                      <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(documento.saidas)}</StyledTableCell>
                      <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(documento.saldo_diario)}</StyledTableCell>
                      <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(documento.saldo_acumulado)}</StyledTableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </StyledTableContainer>

            <ContainerRow>
              <Box>
                <CampoLabel>
                  Valor Total Entradas: <CampoValor>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(relCaixaPrevisao[0].valor_total_entradas)}</CampoValor>
                </CampoLabel>
              </Box>

              <Box>
                <CampoLabel>
                  Valor Total Saídas: <CampoValor>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(relCaixaPrevisao[0].valor_total_saidas)}</CampoValor>
                </CampoLabel>
              </Box>
            </ContainerRow>
          </>
        }
      </Cadastro>

      <ModalRelCaixaPrevisao
        isOpen={modalRelCaixaPrevisaoOpen}
        setIsOpen={toggleModalRelCaixaPrevisao}
        filteringRelCaixaPrevisao={filteringRelCaixaPrevisao}
        handleListCaixaPrevisao={handleListCaixaPrevisao}
        bancos={bancos}
      />        
    </Container>
  );
};

export default DocumentosCaixaPrevisao;
