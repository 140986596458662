import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IDialogProps {
  isOpen: boolean;
  question: string;
  description1?: string;
  description2?: string;
  handleClose: () => void;
  handleNo: () => void;
  handleYes: () => void;
}

const Confirm: React.FC<IDialogProps> = ({
  isOpen,
  question,
  description1,
  description2,
  handleClose,
  handleNo,
  handleYes,
}) => {   
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <span>{description1}</span>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
              <span>{description2}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} color="primary">
            Não
          </Button>
          <Button onClick={handleYes} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Confirm;