import React, { useRef, useCallback, useState } from 'react';
import { FiCheckSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Form } from './styles';
import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import ConfirmDialog from '../ConfirmDialog';

import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

interface IComercial {
   codigocliente: string;
   codigoproduto: string;
   codigoemb: string;
   codigocondicao: string;
   descrinota: string;
   moeda: string;
   precovendamoeda: number;
   precovenda: number;
   capacidade: number;
}

interface IMoeda {
   moeda: string;
   sigla: string;
}

interface IEditComercialData {
   precovendamoeda: number;
   moeda: string;
   codigocondicao: string;
}

interface IModalProps {
   isOpen: boolean;
   setIsOpen: () => void;
   handleUpdateComercial: (comercial: Pick<IComercial, 'precovendamoeda' | 'moeda' | 'codigocondicao'>, confirmUpdatePedidosAbertos: boolean) => void;
   editingComercial: IComercial;
   moedas: IMoeda[],
}

const ModalEditComercial: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  editingComercial,
  handleUpdateComercial,
  moedas,
}) => {   
   const formRef = useRef<FormHandles>(null);
   const { addToast } = useToast();

   const [dialogComercialOpen, setDialogComercialOpen] = useState(false);
   const [confirm, setConfirm] = useState(false);

   const selectMoedas = moedas.map(moeda => {
      return {
         label: moeda.moeda,
         value: moeda.moeda,
      };
   });

   const handleSubmit = useCallback(
      async (data: IEditComercialData, e) => {
         try {
            // const isConfirm = window.confirm("Confirmar?");
            // if (!isConfirm) return; 

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
               precovendamoeda: Yup.number()
                  .transform((_, value) => parseFloat(value.replace(',', '.')))
                  .typeError('Valor inválido')
                  .positive('Valor inválido')
                  .required('Valor obrigatório'),
               moeda: Yup.string().required("Moeda inválida"),
            });

            await schema.validate(data, {
               abortEarly: false,
            });

            if (data.precovendamoeda.toString().includes(',')) {
               data.precovendamoeda = parseFloat(data.precovendamoeda.toString().replace(',','.'));
            }

            handleUpdateComercial(data, confirm);
            setIsOpen();

            addToast({
               type: 'success',
               title: 'Política Atualizada',
               description: 'Política comercial atualizada com sucesso.',
            });
         } catch (err) {
            if (err instanceof Yup.ValidationError) {
               const errors = getValidationErrors(err); 
               formRef.current?.setErrors(errors); 
               return;
            }

            addToast({
               type: 'error',
               title: 'Erro na atualização',
               description: 'Ocorreu um erro ao atualizar a política comercial, tente novamente.',
            });
         }
      },
      [handleUpdateComercial, setIsOpen, addToast, confirm]
   );
   

   function handleDialog(): void {
      setDialogComercialOpen(!dialogComercialOpen);
      setConfirm(false);
   }
  
   const handleDialogClose = () => {
      setDialogComercialOpen(false);
   };

   const handleDialogNo = async () => {
      setDialogComercialOpen(false);
      await setConfirm(false);
      formRef.current?.submitForm();
   };

   const handleDialogYes = async () => { 
      setDialogComercialOpen(false);
      await setConfirm(true);
      formRef.current?.submitForm();
   };

   return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
         <Form 
            ref={formRef}
            // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            onSubmit={handleSubmit}
            initialData={{
               ...editingComercial, 
               precovendamoeda: Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(editingComercial.precovendamoeda)
            }}
         >
            <h1>Política Comercial</h1>
            <p><b>Produto: </b>{editingComercial.codigoproduto} - {editingComercial.descrinota}</p>

            <div onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
               <Input
                  name="precovendamoeda"
                  placeholder="Preço Venda Moeda"
               /> 
               <Select
                  name="moeda"
                  options={selectMoedas}
               />
            </div>

            <button type="button" onClick={handleDialog} data-testid="edit-comercial-button">
               <div className="text">Salvar</div>
               <div className="icon">
                  <FiCheckSquare size={24} />
               </div>
            </button>
         </Form>

         <ConfirmDialog
            isOpen={dialogComercialOpen}
            question='Deseja alterar os pedidos de vendas?'
            description1='NÃO: altera apenas a política comercial selecionada.'
            description2='SIM: altera a política comercial selecionada e os pedidos não faturados.'
            handleClose={handleDialogClose}
            handleNo={handleDialogNo}
            handleYes={handleDialogYes}
         />
      </Modal>
   );
};

export default ModalEditComercial;