import React, { useState, useEffect } from 'react';
import { parseISO, format } from 'date-fns';
import { FiPlusCircle, FiMinusCircle, FiMoreHorizontal } from 'react-icons/fi';
import { useParams, useLocation } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import { Table, TableRow } from '@material-ui/core';

import Header from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import LoadingMore from '../../components/LoadingMore';

import { 
  Container, Titulo, Cadastro, Tabela, TabelaPai, StyledTableHead, StyledTableBody, 
  StrongHeader, StyledTableContainer, StyledTableCell
} from './styles';

import api from '../../services/api';

interface IParametros {
  cliente: string;
}

interface IEmpresa {
  codigoempresa: string;
	nomefantasia: string;
}

interface IAmostra {
  namostra: string;
  codigocliente: string;
  dataemissao: string;
  AMOSTRAPRODUTO: IAmostraProduto[];
}

interface IAmostraProduto {
  namostra: string;
  sequencia: string;
  codigoprodutointerno: string;
  codigoproduto: string;
  nomeproduto: string;
  dataprova: string;
  situacao: string;
  flag_ativo: number;
  cmpinicial: number;
  precounitinicial: number;
  cmp: number;
  PRODUTOINTERNO: IProduto;
  TIPOFLAG: {
    descricao: string;
  }
  APLICACAO_1: {
    descricao: string;
  }
}

interface IProduto {
  codigoProduto: string;
  descricao: string;
  custoRepMoeda: number;
}

interface ILocation {
  searchClient: string;
}

const Comercial: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [toggleAmostra, setToggleAmostra] = useState(false);  
  const [togglePlusAmostra, setTogglePlusAmostra] = useState(false);
  const [indexDivAmostra, setIndexDivAmostra] = useState(-1);
  
  const [empresa, setEmpresa] = useState<IEmpresa>();
  const [amostras, setAmostras] = useState<IAmostra[]>([]);

  const { cliente } = useParams<IParametros>();

  const location = useLocation<ILocation>();

  useEffect(() => { 
    async function getAmostras() {
      try {
        if (currentPage === 1) {
          setLoading(true);

          const resEmpresa = await api.get<IEmpresa>(`/empresas/${cliente}`);
          setEmpresa(resEmpresa.data);
        }

        if (currentPage > 1) {
          setLoadingRows(true); 
        }

        const resAmostras = await api.get<IAmostra[]>(`/amostras/${cliente}?page=${currentPage}`);
        const formattedNewAmostras = resAmostras.data.map(amostra => {
          return {
            ...amostra,
            dataemissao: format(new Date(
              new Date(amostra.dataemissao).getUTCFullYear(), 
              new Date(amostra.dataemissao).getUTCMonth(), 
              new Date(amostra.dataemissao).getUTCDate()
            ), 'dd/MM/yyyy'),
          };
        });
        setAmostras((prevAmostras) => [...prevAmostras, ...formattedNewAmostras]);

      } finally {
        setLoading(false);
        setLoadingRows(false);
      }
    }

    getAmostras();

  }, [cliente, currentPage]);

  function handleToggleAmostra(index: number) {
    setIndexDivAmostra(index);
    setToggleAmostra(true);

    if (index === indexDivAmostra && toggleAmostra) {
      setToggleAmostra(!toggleAmostra);
    }
  };

  function handleTogglePlusAmostra() {
    setTogglePlusAmostra(!togglePlusAmostra);

    if (!togglePlusAmostra) {
      setToggleAmostra(false);
    }
  };

  const handleLoadingMore = () => {
    if (currentPage > 6) {
      setCurrentPage(6);
      return;
    }
    setCurrentPage((currentValue) => currentValue + 1);
  }

  if (loading) {
    return (
      <LoadingPage message='Buscando projetos de amostras do cliente' />
    );
  }

  return (
    <Container isLoading={loadingRows}>
      <Header headerType={'empresa'} empresa={empresa} search={location.state.searchClient} />

      <Cadastro>
        <Titulo>
          <h1>Últimas Amostras</h1>
          <button type="button" onClick={() => { handleTogglePlusAmostra() }}>
            {amostras.length > 0 && !togglePlusAmostra && <FiPlusCircle />}
            {amostras.length > 0 && togglePlusAmostra && <FiMinusCircle />}
          </button>
        </Titulo>

        {amostras.length === 0 && <p>Sem registros...</p>}        

        {amostras && amostras.map((amostra, index) => (
          <Tabela key={index}>

            <TabelaPai>
              <span><strong>Projeto: </strong>{amostra.namostra}</span>
              <span><strong>Dt. Emissão: </strong>{amostra.dataemissao}</span>
              <button type="button" onClick={() => { handleToggleAmostra(index) }}>
                {!togglePlusAmostra && <FiMoreHorizontal />}
              </button>
            </TabelaPai>

            <Spring
              native
              force
              config={{ tension: 5000, friction: 100, precision: 1 }}
              from={{ height: (index === indexDivAmostra && toggleAmostra) || togglePlusAmostra ? 0 : 'auto' }}
              to={{ height: (index === indexDivAmostra && toggleAmostra) || togglePlusAmostra ? 'auto' : 0 }}
            >              
              {props => (
                <animated.div style={props}>
                  <StyledTableContainer>
                      <Table size="small">
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell align="left"><StrongHeader>Código</StrongHeader></StyledTableCell>
                            <StyledTableCell align="left"><StrongHeader>Produto</StrongHeader></StyledTableCell>
                            <StyledTableCell align="left"><StrongHeader>Data</StrongHeader></StyledTableCell>
                            <StyledTableCell align="left"><StrongHeader>Aplicação</StrongHeader></StyledTableCell>
                            <StyledTableCell align="left"><StrongHeader>Situação</StrongHeader></StyledTableCell>
                            <StyledTableCell align="right"><StrongHeader>CMP</StrongHeader></StyledTableCell>
                            <StyledTableCell align="right"><StrongHeader>Valor Unit.</StrongHeader></StyledTableCell>
                            <StyledTableCell align="right"><StrongHeader>Atualizado</StrongHeader></StyledTableCell>
                          </TableRow>
                        </StyledTableHead>
                        <StyledTableBody>
                          {amostra.AMOSTRAPRODUTO.map((amostraproduto, index) => {
                            const custoRepMoeda = isNaN(amostraproduto.PRODUTOINTERNO?.custoRepMoeda) ? 0 : amostraproduto.PRODUTOINTERNO?.custoRepMoeda
                            return (
                              <TableRow key={index}>
                                <StyledTableCell align="left" width="10%">{amostraproduto.codigoproduto}</StyledTableCell>
                                <StyledTableCell align="left" width="20%">{amostraproduto.nomeproduto}</StyledTableCell>
                                <StyledTableCell align="left" width="8%">{amostraproduto.dataprova && format(parseISO(amostraproduto.dataprova), 'dd/MM/yyyy')}</StyledTableCell>
                                <StyledTableCell align="left" width="20%">{amostraproduto.APLICACAO_1?.descricao}</StyledTableCell>
                                <StyledTableCell align="left" width="8%">{amostraproduto.TIPOFLAG?.descricao}</StyledTableCell>
                                <StyledTableCell align="right" width="8%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(amostraproduto.cmpinicial)}</StyledTableCell>
                                <StyledTableCell align="right" width="10%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amostraproduto.precounitinicial)}</StyledTableCell>
                                <StyledTableCell align="right" width="8%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(custoRepMoeda)}</StyledTableCell>
                                {/* <StyledTableCell align="right" width="8%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(amostraproduto.cmp)}</StyledTableCell> */}
                              </TableRow>
                            )
                          })}
                        </StyledTableBody>
                      </Table>
                    </StyledTableContainer>
                </animated.div>
              )}
            </Spring>

          </Tabela>
        ))}
      </Cadastro>

      {amostras.length > 0 && Number.isInteger(amostras.length / 50) && currentPage <= 6 &&
        <LoadingMore 
          loading={loadingRows}
          callback={handleLoadingMore}
        />        
      }
      
    </Container>
  );
};

export default Comercial;