import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const MenuNav = styled.nav`
  background-color: #0F4C81;
  padding: 6px;
`;

export const MenuDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;

  width: 95%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 auto;
`;

export const MenuList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li`
  margin-right: 20px;
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
