import React, { useRef, useCallback, useState } from 'react';
import { FiCheckSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Form } from './styles';
import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import ConfirmDialog from '../ConfirmDialog';

import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

interface IUser {
   id: string;
   name: string;
   email: string;
   autonum: number;
   codigorepre: string;
   USERCAPUANI: {
     nome: string;
   }
 }

interface IFuncionario {
   autonum: number;
   nome: string;
}

interface IEditUserData {
   name: string;
   email: string;
   autonum: number;
}

interface IModalProps {
   isOpen: boolean;
   setIsOpen: () => void;
   handleUpdateUser: (user: Pick<IUser, 'name' | 'email' | 'autonum'>) => void;
   editingUser: IUser;
   funcionarios: IFuncionario[],
}

const ModalEditUser: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  editingUser,
  handleUpdateUser,
  funcionarios,
}) => {   
   const formRef = useRef<FormHandles>(null);
   const { addToast } = useToast();

   const [dialogUserOpen, setDialogUserOpen] = useState(false);
   const [, setConfirm] = useState(false);

   const selectFuncionarios = funcionarios.map(funcionario => {
      return {
         label: funcionario.nome,
         value: funcionario.autonum.toString(),
      };
   });

   const handleSubmit = useCallback(
      async (data: IEditUserData, e) => {
         try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
               name: Yup.string().required("Nome inválido"),
               email: Yup.string().email().required("Email inválido"),
               autonum: Yup.number().positive().required(),
               // codigorepre: Yup.string().nullable(true).matches(/^[0-9]+$/, "Informe apenas dígitos"),
            });

            await schema.validate(data, {
               abortEarly: false,
            });

            // if (data.precovendamoeda.toString().includes(',')) {
            //    data.precovendamoeda = parseFloat(data.precovendamoeda.toString().replace(',','.'));
            // }

            handleUpdateUser(data);
            setIsOpen();

            addToast({
               type: 'success',
               title: 'Usuário Atualizado',
               description: 'Usuário atualizado com sucesso.',
            });
         } catch (err) {
            if (err instanceof Yup.ValidationError) {
               const errors = getValidationErrors(err); 
               formRef.current?.setErrors(errors); 
               return;
            }

            addToast({
               type: 'error',
               title: 'Erro na atualização',
               description: 'Ocorreu um erro ao atualizar o usuário, tente novamente.',
            });
         }
      },
      [handleUpdateUser, setIsOpen, addToast]
   );
   
   function handleDialog(): void {
      setDialogUserOpen(!dialogUserOpen);
      setConfirm(false);
   }
  
   const handleDialogClose = () => {
      setDialogUserOpen(false);
   };

   const handleDialogNo = async () => {
      setDialogUserOpen(false);
      await setConfirm(false);
   };

   const handleDialogYes = async () => { 
      setDialogUserOpen(false);
      await setConfirm(true);
      formRef.current?.submitForm();
   };

   return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
         <Form 
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={editingUser}
         >
            <h1>Cadastro de Usuários</h1>
            {/* <p><b>Nome: </b>{editingUser.name}</p> */}

            <div onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
               <Input
                  name="name"
                  placeholder="Nome"
               /> 
               <Input
                  name="email"
                  placeholder="Email"
               /> 
               <Select
                  name="autonum"
                  options={selectFuncionarios}
               />
            </div>

            <button type="button" onClick={handleDialog} data-testid="edit-user-button">
               <div className="text">Salvar</div>
               <div className="icon">
                  <FiCheckSquare size={24} />
               </div>
            </button>
         </Form>

         <ConfirmDialog
            isOpen={dialogUserOpen}
            question='Confirma as alterações no cadastro do usuário?'
            // description1='NÃO: altera apenas a política comercial selecionada.'
            // description2='SIM: altera a política comercial selecionada e os pedidos não faturados.'
            handleClose={handleDialogClose}
            handleNo={handleDialogNo}
            handleYes={handleDialogYes}
         />
      </Modal>
   );
};

export default ModalEditUser;