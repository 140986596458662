import React, { useEffect, useState } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { Container, UserContainer, Cadastro, Titulo } from './styles';

import api from '../../services/api';

import Header from '../../components/Header';
import UserComponent from '../../components/User';
import LoadingPage from '../../components/LoadingPage';
import ModalEditUser from '../../components/ModalEditUser';
import ModalAddUser from '../../components/ModalAddUser';

interface IUser {
  id: string;
  name: string;
  email: string;
  autonum: number;
  codigorepre: string;
  password?: string;
  USERCAPUANI: {
    nome: string;
  }
}

interface IFuncionario {
  autonum: number;
  nome: string;
}

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [funcionarios, setFuncionarios] = useState<IFuncionario[]>([]);

  // const [addingUser, setAddingUser] = useState<IUser>({} as IUser);
  const [addModalUserOpen, setAddModalUserOpen] = useState(false);

  const [editingUser, setEditingUser] = useState<IUser>({} as IUser);
  const [editModalUserOpen, setEditModalUserOpen] = useState(false);

  useEffect(() => {
    async function getUsers() {
      try {
        setLoading(true);

        const response = await api.get<IUser[]>('/users');
        setUsers(response.data);

        const resFuncionarios = await api.get<IFuncionario[]>(`/funcionarios/findAll`);
        setFuncionarios(resFuncionarios.data);

      } finally {
        setLoading(false);
      }
    }    
    
    getUsers();
  }, []);

  async function handleDeleteUser(id: string): Promise<void> {
    try {
      await api.delete<IUser>('/users', {
        data: {
          id: id
        }
      });

      const listUsers = users.filter(user => user.id !== id);
      setUsers(listUsers);

    } catch (error) {
      console.log(error);
    }
  }

  // --- MODAL ADD USER
  function toggleAddModalUser(): void {
    setAddModalUserOpen(!addModalUserOpen);
  }

  async function handleCreateUser(
    newUser: Pick<IUser, 'id' | 'name' | 'email' | 'autonum' | 'password'>,
  ): Promise<void> {
    try {
      const response = await api.post<IUser>('/users', {
        name: newUser.name,
        email: newUser.email,
        password: newUser.password,
        autonum: newUser.autonum,
      });

      setUsers([...users, response.data]);
    } catch (err) {
      console.log(err);
    }
  }
  // --- MODAL ADD USER

  // --- MODAL EDIT USER
  function handleEditUser(user: IUser): void {
    setEditingUser(user);
    toggleEditModalUser();
  }

  function toggleEditModalUser(): void {
    setEditModalUserOpen(!editModalUserOpen);
  }

  async function handleUpdateUser(
    newUser: Pick<IUser, 'name' | 'email' | 'autonum'>,
  ): Promise<void> {
    try {
      const response = await api.put<IUser>('/users', {
        id: editingUser.id,
        name: newUser.name,
        email: newUser.email,
        autonum: newUser.autonum,
      });

      setUsers(
        users.map(mappedUser =>
          mappedUser.id === editingUser.id ? 
          { 
            ...mappedUser, 
            ...response.data,
          } : mappedUser,
        ),
      );
    } catch (err) {
      console.log(err);
    }
  }
  // --- MODAL EDIT USER

  if (loading) {
    return (
      <LoadingPage message='Buscando usuários' />
    );
  }
  
  return (
    <Container>    
      <Header headerType={'profile'} />

      <ModalAddUser
        isOpen={addModalUserOpen}
        setIsOpen={toggleAddModalUser}
        // addingUser={addingUser}
        handleCreateUser={handleCreateUser}
        funcionarios={funcionarios}
      />

      <ModalEditUser
        isOpen={editModalUserOpen}
        setIsOpen={toggleEditModalUser}
        editingUser={editingUser}
        handleUpdateUser={handleUpdateUser}
        funcionarios={funcionarios}
      />

      <Cadastro>
        <Titulo>
          <h1>Cadastro de Usuários</h1>
          
          <button type="button" onClick={() => { toggleAddModalUser() }}>
            <div className="text">Incluir</div>
            <div className="icon">
                <FiUserPlus size={22} />
            </div>
          </button>
        </Titulo>

        {users.length === 0 && <p>Sem registros...</p>}

        {users && 
          <UserContainer data-testid="user-list">
            {users.map((user, index) => (
              <UserComponent
                key={index}
                user={user}
                handleDeleteUser={handleDeleteUser}
                handleEditUser={handleEditUser}
              />
            ))}
          </UserContainer>
        }
      </Cadastro>
    </Container>
  );
};

export default Users;
