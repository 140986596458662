import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  height: 120px;
  background: #f0f0f5;
`;

export const HeaderContent = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;

  width: 95%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 auto;

  img {
    height: 80px;
    cursor: pointer;

    @media(max-width: 980px) {
      height: 75px;
    }

    @media(max-width: 620px) {
      height: 70px;
    }

    @media(max-width: 450px) {
      height: 60px;
    }
  }

  nav {
    display: flex;
    width: 100%;
    align-items: center;

    div {
      display: flex;
      margin-left: 80px;
      font-size: 20px;
        
      span {
        color: #373435;
        margin-right: 10px;
      }

      @media(max-width: 980px) {
        font-size: 18px;
        margin-left: 60px;
        span {
          margin-right: 8px;
        }
      }    
      @media(max-width: 620px) {
        font-size: 16px;
        margin-left: 40px;
        flex-direction: column;
        span {
          margin-right: 6px;
        }
      }    
      @media(max-width: 450px) {
        margin-left: 20px;
        font-size: 14px;
        span {
          margin-right: 4px;
        }
      }

      a {
        text-decoration: none;
        color: #0F4C81;
  
        &:hover {
          opacity: 0.8;
        }
      }

      strong {
        color: #0F4C81;
      }
    }

    button {
      align-items: center;
      margin-left: auto;
      background: transparent;
      border: 0;
      padding: 0;

      svg {
        color: #373435;
        width: 24px;
        height: 24px;

        &:hover {
          color: ${shade(0.2, '#0F4C81')};
        }
      }    
    }
  }
`;