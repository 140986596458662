import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import api from '../../services/api';
import { Container } from './styles';

interface IComercial {
  codigocliente: string;
	codigoproduto: string;
	codigoemb: string;
	codigocondicao: string;
	descrinota: string;
	moeda: string;
	precovendamoeda: number;
  precovenda: number;
  capacidade: number;
  imprimefax: string;
  EMBALAGEM: {
    descricao: string;
  }
  PRODUTO: {
    custoRepMoeda: number;
    dataCMP: string;
    codigoFamilia: string;
  }
}

interface IMediaTrimestre {
	CODIGOPRODUTO: string;
  TRIMESTRE: number;
}

interface IMediaSemestre {
	CODIGOPRODUTO: string;
  SEMESTRE: number;
}

interface IProps {
  comercial: IComercial;
  mediaTrimestre: IMediaTrimestre[];
  mediaSemestre: IMediaSemestre[];
  handleEditComercial: (comercial: IComercial) => void;
}

const Comercial: React.FC<IProps> = ({ 
  // comercial: comercial,
  // handleEditComercial: handleEditComercial,
  comercial,
  mediaTrimestre,
  mediaSemestre,
  handleEditComercial,
}: IProps) => {
  const [isAtivo, setIsAtivo] = useState(produtoAtivo());
 
  function setEditingComercial(): void {
    handleEditComercial(comercial);
  }

  async function toggleAtivo(): Promise<void> {
    try {
      await api.put('/comercial', {
        codigocliente: comercial.codigocliente,
        codigoproduto: comercial.codigoproduto,          
        codigoemb: comercial.codigoemb,
        precovendamoeda: comercial.precovendamoeda,
        moeda: comercial.moeda,
        imprimefax: produtoImprimeFax(),
      });

      setIsAtivo(!isAtivo)
    } catch (error) {
      console.log(error);
    }
  }

  function produtoAtivo(): boolean {
    return comercial.imprimefax === "S";
  }

  function produtoImprimeFax(): string {
    // Grava o valor inverso da tela.
    return isAtivo ? 'N' : 'S';
  }

  return (
    <Container ativo={isAtivo}>
      <section className="body">
        <div>
          <p><b>Produto: </b>{comercial.codigoproduto}</p>
          <p><b>Moeda: </b>{comercial.moeda}</p>
        </div>
        <div>
          <p><b>Descrição: </b>{comercial.descrinota}</p>
        </div>
        <div>
          <p><b>Embalagem: </b>{comercial.EMBALAGEM && comercial.EMBALAGEM.descricao}</p>
        </div>
        <div>
          <p><b>Preço Venda: </b>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(comercial.precovenda)}</p>
          <p><b>Preço Venda Moeda: </b>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(comercial.precovendamoeda)}</p>
        </div>
        
        <div>
          {mediaTrimestre.length > 0 ?
            mediaTrimestre.map((media, index) => (
              <p key={index}><b>Média Trimestre: </b>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(media.TRIMESTRE)}</p>
            )) 
          :
            <p><b>Média Trimestre: </b>0,000</p>
          }
          {mediaSemestre.length > 0 ?
            mediaSemestre.map((media, index) => (
              <p key={index}><b>Média Semestre: </b>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(media.SEMESTRE)}</p>
            )) 
          :
            <p><b>Média Semestre: </b>0,0000</p>
          }
        </div>

        {(comercial.PRODUTO.codigoFamilia.includes('01') || comercial.PRODUTO.codigoFamilia.includes('10')) ?
          <div>
            <p><b>Última Atualização CMP: </b>{comercial.PRODUTO.dataCMP}</p>
            <p><b>CMP: </b>{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(comercial.PRODUTO.custoRepMoeda)}</p>
          </div>
        :
          <div>
            <p>Sem informações de CMP</p>
          </div>
        }
      </section>

      <section className="footer">
        <div className="icon-container">
          <button
            type="button"
            className="icon"
            onClick={() => setEditingComercial()}
            data-testid={`edit-comercial-${comercial.codigoproduto}`}
          >
            <FiEdit size={20} />
          </button>
        </div>

        <div className="availability-container">
          <p>{isAtivo ? 'Ativo' : 'Inativo'}</p>

          <label htmlFor={`available-switch-${comercial.codigoproduto}`} className="switch">
            <input  
              id={`available-switch-${comercial.codigoproduto}`}
              type="checkbox"
              checked={isAtivo}
              onChange={toggleAtivo}
              data-testid={`change-status-produto-${comercial.codigoproduto}`}
            />
            <span className="slider" />
          </label>
        </div>
      </section>
    </Container>
  );
};

export default Comercial;