import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { FiPlusCircle, FiMinusCircle, FiMoreHorizontal } from 'react-icons/fi';
import { useParams, useLocation } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import { Table, TableRow } from '@material-ui/core';

import Header from '../../components/Header';
import LoadingPage from '../../components/LoadingPage';
import LoadingMore from '../../components/LoadingMore';

import { 
  Container, Titulo, Cadastro, Tabela, TabelaPai, StyledTableHead, StyledTableBody, 
  StrongHeader, StyledTableContainer, StyledTableCell
} from './styles';

import api from '../../services/api';

interface IParametros {
  cliente: string;
}

interface IEmpresa {
  codigoempresa: string;
	nomefantasia: string;
}

interface IFatura {
  nfatura: string;
  codigocliente: string;
  dataemissao: string;
  npedidov: string;
  pedidocliente: string;
  FATURAPRODUTO: IFaturaProduto[];
}

interface IFaturaProduto {
  nfatura: string;
  sequencia: string;
  codigoproduto: string;
  descrinota: string;
  quantidade: number;
  precototal: number;
}

interface ILocation {
  searchClient: string;
}

const Faturas: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingRows, setLoadingRows] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [toggleFatura, setToggleFatura] = useState(false);
  const [togglePlusFatura, setTogglePlusFatura] = useState(false);
  const [indexDivFatura, setIndexDivFatura] = useState(-1);
  
  const [empresa, setEmpresa] = useState<IEmpresa>();
  const [faturas, setFaturas] = useState<IFatura[]>([]);

  const { cliente } = useParams<IParametros>();
  
  const location = useLocation<ILocation>();

  useEffect(() => { 
    async function getFaturas() {
      try {
        if (currentPage === 1) {
          setLoading(true);

          const resEmpresa = await api.get<IEmpresa>(`/empresas/${cliente}`);
          setEmpresa(resEmpresa.data);
        }

        if (currentPage > 1) {
          setLoadingRows(true); 
        }

        const resFaturas = await api.get<IFatura[]>(`/faturas/${cliente}?page=${currentPage}`);
        const formattedFaturas = resFaturas.data.map(fatura => {
          return {
            ...fatura,
            dataemissao: format(new Date(
              new Date(fatura.dataemissao).getUTCFullYear(), 
              new Date(fatura.dataemissao).getUTCMonth(), 
              new Date(fatura.dataemissao).getUTCDate()
            ), 'dd/MM/yyyy'),
          };
        });
        setFaturas((prevFaturas) => [...prevFaturas, ...formattedFaturas]);
        
      //} catch (error) {
      //  alert('Cliente inválido!');
      } finally {
        setLoading(false);
        setLoadingRows(false);
      }
    }
    getFaturas();

  }, [cliente, currentPage]);

  function handleToggleFatura(index: number) {
    setIndexDivFatura(index);
    setToggleFatura(true);

    if (index === indexDivFatura && toggleFatura) {
      setToggleFatura(!toggleFatura);
    }
  };

  function handleTogglePlusFatura() {
    setTogglePlusFatura(!togglePlusFatura);

    if (!togglePlusFatura) {
      setToggleFatura(false);
    }
  };

  const handleLoadingMore = () => {
    if (currentPage > 6) {
      setCurrentPage(6);
      return;
    }
    setCurrentPage((currentValue) => currentValue + 1);
  }

  if (loading) {
    return (
      <LoadingPage message='Buscando notas fiscais do cliente' />
    );
  }

  return (
    <Container>
      <Header headerType={'empresa'} empresa={empresa} search={location.state.searchClient} />

      <Cadastro>
        <Titulo>
          <h1>Últimas Faturas</h1>
          <button type="button" onClick={() => { handleTogglePlusFatura() }}>
            {faturas.length > 0 && !togglePlusFatura && <FiPlusCircle />}
            {faturas.length > 0 && togglePlusFatura && <FiMinusCircle />}
          </button>
        </Titulo>

        {faturas.length === 0 && <p>Sem registros...</p>}

        {faturas && faturas.map((fatura, index) => (
          <Tabela key={index}>

            <TabelaPai>
              <span><strong>Nota Fiscal: </strong>{fatura.nfatura}</span>
              <span><strong>Dt. Emissão: </strong>{fatura.dataemissao}</span>
              <span><strong>Nº Pedido: </strong>{fatura.npedidov}</span>
              {fatura.pedidocliente && <span><strong>Nº Pedido Cliente: </strong>{fatura.pedidocliente}</span>}

              <button type="button" onClick={() => { handleToggleFatura(index) }}>
                {!togglePlusFatura && <FiMoreHorizontal />}
              </button>
            </TabelaPai>

            <Spring
              native
              force
              config={{ tension: 5000, friction: 100, precision: 1 }}
              from={{ height: (index === indexDivFatura && toggleFatura) || togglePlusFatura ? 0 : 'auto' }}
              to={{ height: (index === indexDivFatura && toggleFatura) || togglePlusFatura ? 'auto' : 0 }}
            >              
              {props => (
                <animated.div style={props}>
                  <StyledTableContainer>
                    <Table size="small">
                      <StyledTableHead>
                        <TableRow>
                          <StyledTableCell align="left"><StrongHeader>Código</StrongHeader></StyledTableCell>
                          <StyledTableCell align="left"><StrongHeader>Produto</StrongHeader></StyledTableCell>
                          <StyledTableCell align="right"><StrongHeader>Quantidade</StrongHeader></StyledTableCell>
                          <StyledTableCell align="right"><StrongHeader>Valor</StrongHeader></StyledTableCell>
                        </TableRow>
                      </StyledTableHead>
                      <StyledTableBody>
                        {fatura.FATURAPRODUTO?.map((faturaproduto, index) => (
                          <TableRow key={index}>
                            <StyledTableCell align="left" width="12%">{faturaproduto.codigoproduto}</StyledTableCell>
                            <StyledTableCell align="left">{faturaproduto.descrinota}</StyledTableCell>
                            <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(faturaproduto.quantidade)}</StyledTableCell>
                            <StyledTableCell align="right" width="15%">{Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(faturaproduto.precototal)}</StyledTableCell>
                          </TableRow>
                        ))}
                      </StyledTableBody>
                    </Table>
                  </StyledTableContainer>                
                </animated.div>
              )}
            </Spring>

          </Tabela>
        ))}
      </Cadastro>

      {faturas.length > 0 && Number.isInteger(faturas.length / 50) && currentPage <= 6 &&
        <LoadingMore 
          loading={loadingRows}
          callback={handleLoadingMore}
        />        
      }
    </Container>
  );
};

export default Faturas;
