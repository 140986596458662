import styled from 'styled-components';

export const Container = styled.div`
  font-size: 62.5%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f0f0f0;
  width: 100%;
  height: 100vh;

  p {
    font-size: 30px;
    margin: 50px;

    @media(max-width: 980px) {
      font-size: 25px;
      margin: 40px;
    }    
    @media(max-width: 620px) {
      font-size: 20px;
      margin: 35px;
    }
    @media(max-width: 450px) {
      font-size: 16px;
      margin: 30px;
    }
  }
`;